import { useEffect,useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTheme } from '@mui/material/styles';
import { Grid, Container } from '@mui/material';

import {
  AppCurrentVisits,
  AppWebsiteVisits,
  AppWidgetSummary, 
} from '../sections/@dashboard/app';

import { getTelephoneMaterialCount,getTelephoneMaterialSaleCount,getSoldOldDevices,getWaitingDeviceRepair,getFinishedDeviceRepair,getDeviceRepairTotalEarnig,getDeviceRepairTotalExpense,getOlDevicesEarning,getOlDevicesExpense,getTelephoneMaterialSalesEarning,getTelephoneMaterialSalesExpenses,getTelephoneMaterialSalesYearlyCount,getDeviceRepairYearlyCount,getOldDeviceYearlyCount } from '../services'; 

// ----------------------------------------------------------------------

export default function DashboardAppPage() {
  const theme = useTheme();
  const [materialCount,setMaterialCount] = useState(0);
  const [saleCount,setSaleCount] = useState(0);
  const [waitingDevice,setWaitingDevice] = useState(0);
  const [fixedDevice,setFixedDevice] = useState(0);
  const [soldDevice,setSoldDevices] = useState(0)
  const [deviceRepairEarnig,setDeviceReapirEarning] = useState(0)
  const [deviceRepairExpense,setDeviceRepairExpense] = useState(0)
  const [deviceSoldEarning,setDeviceSoldEarnig] = useState(0)
  const [deviceSoldExpense,setDeviceSoldExpense] = useState(0)
  const [salesEarning,setSalesEarning] = useState(0)
  const [salesExpense,setSalesExpense] = useState(0)
  const [yearlySales,setYearlySales] = useState([])
  const [yearlyRepairs,setYearlyRepairs] = useState([])
  const [yearlyDevices,setYearlyDevices] = useState([])
 
  useEffect(() => {
    getMaterialCount();
    getSaleCount()

    getSoldDevices()
    getWaitingDevice()

    getFixedDevice()

    getDeviceEarning()
    getDeviceExpense()

    getSoldDeviceEarning()
    getSoldDeviceExpense()

    getSaleTotalEarning()
    getSaleTotalExpense()

    getYearlySales()
    getYearlyRepairs()
    getYearlyDevices()
}, []);

const getYearlyDevices = () => {
  getOldDeviceYearlyCount()
        .then((data) => setYearlyDevices(data))
        .catch((err) => console.log(err));

};
const getYearlyRepairs = () => {
  getDeviceRepairYearlyCount()
        .then((data) => setYearlyRepairs(data))
        .catch((err) => console.log(err));

};
const getYearlySales = () => {
  getTelephoneMaterialSalesYearlyCount()
        .then((data) => setYearlySales(data))
        .catch((err) => console.log(err));

};
const getSaleTotalEarning = () => {
  getTelephoneMaterialSalesEarning()
        .then((data) => setSalesEarning(data))
        .catch((err) => console.log(err));

};
const getSaleTotalExpense = () => {
  getTelephoneMaterialSalesExpenses()
        .then((data) => setSalesExpense(data))
        .catch((err) => console.log(err));

};
const getMaterialCount = () => {
  getTelephoneMaterialCount()
        .then((data) => setMaterialCount(data))
        .catch((err) => console.log(err));

};
const getSaleCount = () => {
  getTelephoneMaterialSaleCount()
        .then((data) => setSaleCount(data))
        .catch((err) => console.log(err));

};
const getSoldDevices = () => {
  getSoldOldDevices()
        .then((data) => setSoldDevices(data))
        .catch((err) => console.log(err));

};
const getWaitingDevice = () => {
  getWaitingDeviceRepair()
        .then((data) => setWaitingDevice(data))
        .catch((err) => console.log(err));

};
const getFixedDevice = () => {
  getFinishedDeviceRepair()
        .then((data) => setFixedDevice(data))
        .catch((err) => console.log(err));

};
const getDeviceEarning = () => {
  getDeviceRepairTotalEarnig()
        .then((data) => setDeviceReapirEarning(data))
        .catch((err) => console.log(err));

};
const getDeviceExpense = () => {
  getDeviceRepairTotalExpense()
        .then((data) => setDeviceRepairExpense(data))
        .catch((err) => console.log(err));

};
const getSoldDeviceEarning = () => {
  getOlDevicesEarning()
        .then((data) => setDeviceSoldEarnig(data))
        .catch((err) => console.log(err));

};
const getSoldDeviceExpense = () => {
  getOlDevicesExpense()
        .then((data) => setDeviceSoldExpense(data))
        .catch((err) => console.log(err));

};
function formatNumberWithCommas(number) {
  const formatter = new Intl.NumberFormat('tr-TR');
  return formatter.format(number);
}

  return (
    <>
      <Helmet>
        <title> Teknik Servis </title>
      </Helmet>

      <Container maxWidth="xl">
        

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Toplam Ürün" total={materialCount || '0'} icon={'ant-design:code-sandbox-outlined'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Toplam Satış" total={saleCount || '0'} color="info" icon={'ant-design:stock-outlined'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Satılan Cihaz" total={soldDevice || '0'} color="success" icon={'ant-design:mobile-outlined'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Bekleyen Cihaz" total={waitingDevice || '0'} color="warning" icon={'ant-design:mobile-outlined'} />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentVisits
              title="Oranlar"
              chartData={[
                { label: 'Telefon Satış', value: soldDevice },
                { label: 'Malzeme Satış', value: saleCount },
                { label: 'Tamir Edilen Cihaz', value: fixedDevice }
                
              ]}
              chartColors={[
                theme.palette.primary.main,
                theme.palette.info.main,
                theme.palette.warning.main,
                
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentVisits
             title={`Toplam Gelir: ${formatNumberWithCommas(deviceSoldEarning + deviceRepairEarnig + salesEarning)} ₺`}
              chartData={[
                { label: 'Telefon Satış', value: deviceSoldEarning },
                { label: 'Malzeme Satış', value: salesEarning },              
                { label: 'Tamir Edilen Cihaz', value: deviceRepairEarnig }
                
              ]}
              chartColors={[
                theme.palette.primary.main,
                theme.palette.info.main,
                theme.palette.warning.main,              
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentVisits
              title={`Toplam Gider: ${formatNumberWithCommas(deviceSoldExpense + deviceRepairExpense + deviceRepairExpense) } ₺`}
              chartData={[
                { label: 'Telefon Satış', value: deviceSoldExpense },
                { label: 'Malzeme Satış', value: salesExpense },         
                { label: 'Tamir Edilen Cihaz', value: deviceRepairExpense }
                
              ]}
              chartColors={[
                theme.palette.primary.main,
                theme.palette.info.main,
                theme.palette.warning.main,              
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppWebsiteVisits
              title="Aylık Rapor"
              
              chartLabels={[
                'Ocak',
                'Şubat',
                'Mart',
                'Nisan',
                'Mayıs',
                'Haziran',
                'Temmuz',
                'Ağustos',
                'Eylül',
                'Ekim',
                'Kasım',
                'Aralık',
                
              ]}
              chartData={[
                {
                  name: 'Telefon Satış',
                  type: 'line',
                  fill: 'solid',
                  data: yearlyDevices.map(item => item.salesCount),
                },
                {
                  name: 'Tamir Edilen Cihaz',
                  type: 'line',
                  fill: 'solid',
                  data: yearlyRepairs.map(item => item.salesCount),
                },
                {
                  name: 'Malzeme Satış',
                  type: 'line',
                  fill: 'solid',
                  data: yearlySales.map(item => item.salesCount),
                },
              ]}
            />
          </Grid>   
          
        </Grid>
      </Container>
    </>
  );
}
