import { useState } from 'react';
import PropTypes from 'prop-types'; // Bu import ekleyin
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    Card,
    Stack,
    Button,
    Container,
    TextField,
    Modal,
} from '@mui/material';
import Iconify from '../../../components/iconify';
import { addTelephoneModel } from '../../../services';

function TelephoneModelAddModal({ onAddSuccess }) {
    const [isOpen, setIsOpen] = useState(false);
    const [telephoneModelName, setTelephoneModelName] = useState('');

    const openModal = () => {
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
    };

    const submitHandle = (e) => {
        e.preventDefault();
        const data = {
            telephoneModelName,
        };

        addTelephoneModel(data)
            .then((res) => {
                if (res.success === true) {
                    toast.dismiss();
                    toast.success(res.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });

                    // Veri ekleme işlemi başarılı olduğunda dışarıya bir bildirim gönderelim.
                    if (onAddSuccess) {
                        onAddSuccess();
                    }

                    closeModal();
                    setTelephoneModelName('');
                }
            })
            .catch((err) => console.log(err));
    };

    return (
        <>
       <ToastContainer />
            <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={openModal} marginInlineStart = "auto" >
                Yeni Ekle
            </Button>
            
            <Modal open={isOpen} onClose={closeModal}>
                <div
                    style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400 }}
                >
                    <Card>
                        <Container sx={{ p: 4 }}>
                            <form onSubmit={submitHandle}>
                                <Stack spacing={3}>
                                    <TextField
                                        type="text"
                                        name="telephoneCategoryName"
                                        value={telephoneModelName}
                                        label="Model İsmi"
                                        onChange={(e) => setTelephoneModelName(e.target.value)}
                                    />
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        fullWidth
                                        disabled={!telephoneModelName}
                                    >
                                        Kaydet
                                    </Button>
                                </Stack>
                            </form>
                        </Container>
                    </Card>
                </div>
            </Modal>
        </>
    );
}
TelephoneModelAddModal.propTypes = {
    onAddSuccess: PropTypes.func, // onAddSuccess prop'unun bir fonksiyon olduğunu belirtin
};
export default TelephoneModelAddModal;