import { useEffect, useState, Fragment } from 'react';
import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// @mui
import {
    Card,
    Table,
    Paper,
    Stack,
    Checkbox,
    TableRow,
    TableBody,
    TableCell,
    Typography,
    IconButton,
    TableContainer,
    TablePagination,
    Button,
    Divider,
    Popover,
    MenuItem,
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Label from '../../components/label'; // Eğer 'components' klasörü 'telephoneCategory' klasörüne üst düzeyde ise
import Iconify from '../../components/iconify';
// components

import Scrollbar from '../../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../../sections/@dashboard/user';
import { getTelephoneWithDetails, deleteTelephone, updateTelephoneStatus } from '../../services';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
    { id: 'title', label: 'ActionId', alignRight: false },
    { id: 'model', label: 'Model', alignRight: false },
    { id: 'kategori', label: 'Kategori', alignRight: false },
    { id: 'usta', label: 'Usta', alignRight: false },
    { id: 'price', label: 'Ücret', alignRight: false },
    { id: 'cDate', label: 'Tarih', alignRight: false },
    { id: 'isApproved', label: 'Durum', alignRight: false },
    { id: '', label: 'İşlemler', alignRight: false },
];

// ----------------------------------------------------------------------
function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(
            array,
            (telephonecategory) => telephonecategory.actionId.toLowerCase().indexOf(query.toLowerCase()) !== -1
        );
    }
    return stabilizedThis.map((el) => el[0]);
}



export default function TelephoneMaterialList() {
    const [page, setPage] = useState(0);
    const [order, setOrder] = useState('asc');
    const [selected, setSelected] = useState([]);
    const [orderBy, setOrderBy] = useState('name');
    const [filterName, setFilterName] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [telephonecategories, setTelephoneCategories] = useState([]);
    const [openDetails, setOpenDetails] = useState({});
    const navigate = useNavigate();
    const [open, setOpen] = useState(null);
    const [anchorId, setAnchorId] = useState(null);

    const handleOpenMenu = (event, telephoneId) => {
        setOpen(event.currentTarget);
        setAnchorId(telephoneId);
      };
    const handleCloseMenu = () => {
        setOpen(null);
    };
    const toggleDetails = (telephoneId) => {
        setOpenDetails((prevState) => ({
            ...prevState,
            [telephoneId]: !prevState[telephoneId],
        }));

    };

    useEffect(() => {
        getAll();
    }, []);

    const getAll = () => {
        getTelephoneWithDetails()
            .then((data) => setTelephoneCategories(data))
            .catch((err) => console.log(err));

    };

    const deleteCategory = (id) => {
        deleteTelephone(id)
            .then((res) => {
                if (res.success === true) {
                    toast.success(res.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                }
                getAll();
                handleCloseMenu()
            })
            .catch((err) => {
                toast.error(err.message, {
                    position: toast.POSITION.BOTTOM_RIGHT
                })
            });
    };

    const updateTelephoneApprove = (id) => {
        updateTelephoneStatus(id)
            .then((res) => {
                if (res.success === true) {
                    toast.success(res.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                }
                getAll();
                handleCloseMenu()
            })
            .catch((err) => {
                toast.error(err.message, {
                    position: toast.POSITION.BOTTOM_RIGHT
                })
            });
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = telephonecategories.map((n) => n.title);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
    };

    function formatDate(dateString) {
        // Tarih dizesini JavaScript Date nesnesine dönüştür
        const date = new Date(dateString);

        // Tarih nesnesini istediğiniz formata biçimlendirin (örneğin: yyyy-MM-dd)
        const formattedDate = date.toLocaleDateString('tr-TR', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
        });

        return formattedDate;
    }
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const handleFilterByName = (event) => {
        setPage(0);
        const query = event.target.value.toLowerCase(); // Arama sorgusunu küçük harfe çevirin
        setFilterName(query);
    };

    function formatNumberWithCommas(number) {
        const formatter = new Intl.NumberFormat('tr-TR');
        return formatter.format(number);
    }
    const filteredUsers = applySortFilter(telephonecategories, getComparator(order, orderBy), filterName);
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - telephonecategories.length) : 0;
    const isNotFound = !filteredUsers.length && !!filterName;
    const uniqueTelephoneMaterials = []; // Benzersiz telephoneMaterialSaleId değerlerini saklamak için bir dizi oluşturun
    filteredUsers.forEach((row) => {
        const { telephoneId } = row;

        // Eğer bu telephoneMaterialSaleId daha önce eklenmemişse, diziye ekleyin
        if (!uniqueTelephoneMaterials.some((item) => item.telephoneId === telephoneId)) {
            uniqueTelephoneMaterials.push(row);
        }
    });
    return (
        <>
            <ToastContainer />
            <Helmet>
                <title> Cihazlar </title>
            </Helmet>

            
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Cihazlar
                    </Typography>
                    <Link to="/dashboard/telephoneadd">
                        <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
                            Yeni Ekle
                        </Button>
                    </Link>
                </Stack>

                <Card>
                    <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />
                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 800 }}>
                            <Table>
                                <UserListHead
                                    order={order}
                                    orderBy={orderBy}
                                    headLabel={TABLE_HEAD}
                                    rowCount={telephonecategories.length}
                                    numSelected={selected.length}
                                    onRequestSort={handleRequestSort}
                                    onSelectAllClick={handleSelectAllClick}
                                />

                                <TableBody>
                                    {uniqueTelephoneMaterials.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                        const { telephoneId, telephoneCategoryName, telephoneMasterName, actionId, telephoneModelName, price, createdDate, isApproved } = row;
                                        
                                        const selectedUser = selected.indexOf(telephoneId) !== -1;
                                        const isDetailsOpen = openDetails[telephoneId];

                                        const relatedRecords = filteredUsers.filter(item => item.telephoneId === telephoneId);
                                        return (
                                            <Fragment key={telephoneId}>
                                                <TableRow hover key={telephoneId} tabIndex={-1} role="checkbox" selected={selectedUser}>
                                                    <TableCell padding="checkbox">
                                                        <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, telephoneId)} />
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        <Label color="primary">{actionId}</Label></TableCell>
                                                    <TableCell align="left">
                                                        <Label color="secondary">{telephoneModelName}</Label>
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        <Label color="info">
                                                            {telephoneCategoryName}
                                                        </Label>
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        <Label color="error">{telephoneMasterName}</Label>
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        <Label color="success">{price} ₺</Label>
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        <Label color="default">{formatDate(createdDate)}</Label>
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        <Label color={isApproved ? "success" : "warning"}>
                                                            {isApproved ? "Tamir Edildi" : "İlgileniliyor"}
                                                        </Label>
                                                    </TableCell>
                                                    <TableCell>
                                                        <IconButton size="large" color="inherit" sx={{ opacity: 0.48 }} onClick={(event) => handleOpenMenu(event, telephoneId)}>
                                                            <Iconify icon={'eva:more-vertical-fill'} />
                                                        </IconButton>
                                                        <Popover
                                                        
                                                            open={Boolean(open)}
                                                            anchorEl={open}
                                                            onClose={handleCloseMenu}
                                                            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                                                            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                                                            PaperProps={{
                                                                sx: {
                                                                    p: 1,
                                                                    '& .MuiMenuItem-root': {
                                                                        px: 1,
                                                                        typography: 'body2',
                                                                        borderRadius: 0.75,
                                                                    },
                                                                },
                                                            }}
                                                        >
                                                            <MenuItem sx={{ color: 'success.main' }} onClick={() => updateTelephoneApprove(anchorId)}>
                                                                <Iconify icon={'eva:checkmark-circle-2-fill'} sx={{ mr: 2 }} />
                                                                Tamiri Tamamla

                                                            </MenuItem>
                                                            <MenuItem sx={{ color: 'info.main' }} onClick={() => navigate(`/dashboard/telephones/edit/${anchorId}`)}>
                                                                <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
                                                                Güncelle 
                                                            </MenuItem>
                                                            <MenuItem sx={{ color: 'default.main' }}>
                                                                <Iconify icon={'bxs:file-pdf'} sx={{ mr: 2 }} />
                                                                PDF Çıktısı Al

                                                            </MenuItem>
                                                            <Divider sx={{ borderStyle: 'dashed' }} />

                                                            <MenuItem sx={{ color: 'error.main' }} onClick={() => deleteCategory(anchorId)}>
                                                                <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
                                                                Sil 
                                                            </MenuItem>
                                                        </Popover>
                                                        <IconButton
                                                            aria-label="details"
                                                            onClick={() => toggleDetails(telephoneId)}
                                                        >
                                                            {isDetailsOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                                        </IconButton>

                                                    </TableCell>
                                                </TableRow>
                                                {isDetailsOpen && (
                                                    relatedRecords.map((relatedRow) => (
                                                        <TableRow key={relatedRow.telephoneId}>
                                                            <TableCell colSpan={9}>
                                                                <Label color="primary" style={{ marginRight: '2px' }}>{relatedRow.telephoneMaterialName}</Label>
                                                                <Label color="info" style={{ marginRight: '2px' }}>Adet: {relatedRow.quantity}</Label>
                                                                <Label color="warning" style={{ marginRight: '2px' }}>Fiyat: {formatNumberWithCommas(relatedRow.telephoneMaterialSubTotal)} ₺</Label>
                                                                <Label color="success" style={{ marginRight: '2px' }}>Toplam Fiyat: {formatNumberWithCommas((relatedRow.quantity) * (relatedRow.telephoneMaterialSubTotal))} ₺</Label>
                                                                <hr />
                                                            </TableCell>
                                                        </TableRow>
                                                    ))
                                                )}
                                            </Fragment>

                                        );
                                    })}
                                    {emptyRows > 0 && (
                                        <TableRow style={{ height: 53 * emptyRows }}>
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>
                                {isNotFound && (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                                <Paper
                                                    sx={{
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    <Typography variant="h6" paragraph>
                                                        Sonuç Bulunamadı
                                                    </Typography>

                                                    <Typography variant="body2">
                                                        aramak istediğiniz &nbsp;
                                                        <strong>&quot;{filterName}&quot;</strong>.
                                                        <br /> Aradığınız kelimelere uygun sonuç bulunmamaktadır.
                                                    </Typography>
                                                </Paper>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </Scrollbar>

                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={uniqueTelephoneMaterials.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Card>
            
        </>
    );
}
