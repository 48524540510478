import { useEffect, useState, Fragment } from 'react';
import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// @mui
import {
    Card,
    Table,
    Paper,
    Stack,
    Checkbox,
    TableRow,
    TableBody,
    TableCell,
    
    Typography,
    IconButton,
    TableContainer,
    TablePagination,
    Button,

} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Label from '../../../components/label'; // Eğer 'components' klasörü 'telephoneCategory' klasörüne üst düzeyde ise
import Iconify from '../../../components/iconify';
// components

import Scrollbar from '../../../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../../../sections/@dashboard/user';
import { getTelephoneMaterialSaleDetails, deleteTelephoneMaterialSale } from '../../../services';

// ----------------------------------------------------------------------

const TABLE_HEAD = [

    { id: 'telephoneMaterialSaleId', label: 'Id', alignRight: false },
    { id: 'title', label: 'Başlık', alignRight: false },
    { id: 'salesPrice', label: 'Fiyat (₺)', alignRight: false },
    { id: 'salesExpense', label: 'Masraf (₺)', alignRight: false },
    { id: 'salesEarning', label: 'Kar (₺)', alignRight: false },
    { id: 'cDate', label: 'Tarih', alignRight: false },
    { id: '', label: 'İşlemler', alignRight: false },

];

// ----------------------------------------------------------------------
function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(
            array,
            (telephonecategory) => telephonecategory.title.toLowerCase().indexOf(query.toLowerCase()) !== -1
        );
    }
    return stabilizedThis.map((el) => el[0]);
}

export default function TelephoneMaterialSaleList() {

    const [page, setPage] = useState(0);
    const [order, setOrder] = useState('asc');
    const [selected, setSelected] = useState([]);
    const [orderBy, setOrderBy] = useState('name');
    const [filterName, setFilterName] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [telephonecategories, setTelephoneCategories] = useState([]);
    const navigate = useNavigate();
    const [openDetails, setOpenDetails] = useState({});
    const toggleDetails = (telephoneMaterialSaleId) => {
        setOpenDetails((prevState) => ({
            ...prevState,
            [telephoneMaterialSaleId]: !prevState[telephoneMaterialSaleId],
        }));
        
    };

    useEffect(() => {
        getAll();
    }, []);

    const getAll = () => {
        getTelephoneMaterialSaleDetails()
            .then((data) => setTelephoneCategories(data))
            .catch((err) => console.log(err));

    };

    function formatDate(dateString) {
        // Tarih dizesini JavaScript Date nesnesine dönüştür
        const date = new Date(dateString);

        // Tarih nesnesini istediğiniz formata biçimlendirin (örneğin: yyyy-MM-dd)
        const formattedDate = date.toLocaleDateString('tr-TR', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
        });

        return formattedDate;
    }

    const deleteCategory = (id) => {
        deleteTelephoneMaterialSale(id)
            .then((res) => {
                if (res.success === true) {
                    toast.success(res.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                }
                getAll();
            })
            .catch((err) => {
                toast.error(err.message, {
                    position: toast.POSITION.BOTTOM_RIGHT
                })
            });
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = uniqueTelephoneMaterials.map((n) => n.title);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const handleFilterByName = (event) => {
        setPage(0);
        const query = event.target.value.toLowerCase(); // Arama sorgusunu küçük harfe çevirin
        setFilterName(query);
    };

    function formatNumberWithCommas(number) {
        const formatter = new Intl.NumberFormat('tr-TR');
        return formatter.format(number);
    }

    const filteredUsers = applySortFilter(telephonecategories, getComparator(order, orderBy), filterName);
    const uniqueTelephoneMaterials = []; // Benzersiz telephoneMaterialSaleId değerlerini saklamak için bir dizi oluşturun
    filteredUsers.forEach((row) => {
        const { telephoneMaterialSaleId } = row;

        // Eğer bu telephoneMaterialSaleId daha önce eklenmemişse, diziye ekleyin
        if (!uniqueTelephoneMaterials.some((item) => item.telephoneMaterialSaleId === telephoneMaterialSaleId)) {
            uniqueTelephoneMaterials.push(row);
        }
    });
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - uniqueTelephoneMaterials.length) : 0;
    const isNotFound = !uniqueTelephoneMaterials.length && !!filterName;

    return (
        <>
            <ToastContainer />
            <Helmet>
                <title> Malzeme Satışları </title>
            </Helmet>

           
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Malzeme Satışları
                    </Typography>
                    <Link to="/dashboard/telephonematerialsaleadd">
                        <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
                            Yeni Ekle
                        </Button>
                    </Link>
                </Stack>

                <Card>
                    <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />
                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 800 }}>
                            <Table>
                                <UserListHead
                                    order={order}
                                    orderBy={orderBy}
                                    headLabel={TABLE_HEAD}
                                    rowCount={uniqueTelephoneMaterials.length}
                                    numSelected={selected.length}
                                    onRequestSort={handleRequestSort}
                                    onSelectAllClick={handleSelectAllClick}
                                />

                                <TableBody>
                                    {uniqueTelephoneMaterials.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                        const { telephoneMaterialSaleId, actionId,title, salesPrice, createdDate,salesExpense } = row;
                                        const selectedUser = selected.indexOf(telephoneMaterialSaleId) !== -1;
                                        const isDetailsOpen = openDetails[telephoneMaterialSaleId];
                                        const relatedRecords = filteredUsers.filter(item => item.telephoneMaterialSaleId === telephoneMaterialSaleId);

                                        return (
                                            <Fragment key={telephoneMaterialSaleId}>
                                                <TableRow hover tabIndex={-1} role="checkbox" selected={selectedUser}>
                                                    <TableCell padding="checkbox">
                                                        <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, telephoneMaterialSaleId)} />
                                                    </TableCell>
                                                    <TableCell align="left"><Label color="info">{actionId}</Label></TableCell>

                                                    <TableCell align="left">
                                                        <Label color="primary">{title}</Label>
                                                    </TableCell>
                                                    <TableCell align="left"><Label color="info">{formatNumberWithCommas(salesPrice)} ₺</Label></TableCell>
                                                    <TableCell align="left"><Label color="warning">{formatNumberWithCommas(salesExpense)} ₺</Label></TableCell>
                                                    <TableCell align="left"><Label color="success">{formatNumberWithCommas(salesPrice-salesExpense)} ₺</Label></TableCell>
                                                    <TableCell align="left"><Label color="default">{formatDate(createdDate)}</Label></TableCell>
                                                    <TableCell>
                                                        <IconButton aria-label="delete" color="error" onClick={() => deleteCategory(telephoneMaterialSaleId)} >
                                                            <DeleteIcon />
                                                        </IconButton>
                                                        <IconButton
                                                            aria-label="edit"
                                                            color="primary"
                                                            onClick={() => navigate(`/dashboard/telephonematerialsales/edit/${telephoneMaterialSaleId}`)}
                                                        >
                                                            <EditIcon />
                                                        </IconButton>
                                                        <IconButton
                                                            aria-label="details"
                                                            onClick={() => toggleDetails(telephoneMaterialSaleId)}
                                                        >
                                                            {isDetailsOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>

                                                {isDetailsOpen && (
                                                    relatedRecords.map((relatedRow) => (
                                                        <TableRow key={relatedRow.telephoneMaterialSaleId}>
                                                            <TableCell colSpan={7}>
                                                                <Label color="primary" style={{ marginRight: '2px' }}>{relatedRow.telephoneMaterialName}</Label>
                                                                <Label color="info" style={{ marginRight: '2px' }}>Adet: {relatedRow.quantity}</Label>
                                                                <Label color="warning" style={{ marginRight: '2px' }}>Fiyat: {formatNumberWithCommas(relatedRow.telephoneMaterialSubTotal)} ₺</Label>
                                                                <Label color="success" style={{ marginRight: '2px' }}>Toplam Fiyat: {formatNumberWithCommas((relatedRow.quantity) * (relatedRow.telephoneMaterialSubTotal))} ₺</Label>
                                                                <hr />
                                                            </TableCell>
                                                        </TableRow>
                                                    ))
                                                )}
                                            </Fragment>
                                        );
                                    })}
                                    {emptyRows > 0 && (
                                        <TableRow style={{ height: 53 * emptyRows }}>
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>
                                {isNotFound && (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                                <Paper
                                                    sx={{
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    <Typography variant="h6" paragraph>
                                                        Sonuç Bulunamadı
                                                    </Typography>

                                                    <Typography variant="body2">
                                                        aramak istediğiniz &nbsp;
                                                        <strong>&quot;{filterName}&quot;</strong>.
                                                        <br /> Aradığınız kelimelere uygun sonuç bulunmamaktadır.
                                                    </Typography>
                                                </Paper>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </Scrollbar>

                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={uniqueTelephoneMaterials.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Card>
           
        </>
    );
}
