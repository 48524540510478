import * as React from 'react';
import { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useNavigate,useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { toast, ToastContainer } from 'react-toastify';
import tr from "dayjs/locale/tr";
import 'react-toastify/dist/ReactToastify.css';
import {
    Card,
    Container,
    Stack,
    Typography,
    Autocomplete,
    Box,
    Input,
    FilledInput,
    InputLabel,
    InputAdornment,
    FormControl,
    TextField,
    Button,
    ListItem,
    ListItemText,
    List,

} from '@mui/material';

import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { updateDeviceRepair, getTelephoneModels,getDeviceRepairById } from '../../../services';

export default function OldDeviceAdd() {
    const [repairPrice, setRepairPrice] = useState('0');
    const [customerPrice, setCustomerPrice] = useState('0');
    const [totalPrice, setTotalPrice] = useState('0');
    const [selectedModel, setSelectedModel] = useState(null);
    const [models, setModels] = useState([])

    const { id: deviceRepairId } = useParams();

    const [deviceRepair,setDeviceRepair] = useState([])
    const [description,setDescription] = useState('')
    const [deviceActions,setDeviceActions] = useState('')
    const [devicePassword, setDevicePassword] = useState('')
    const [customerName, setCustomerName] = useState('')
    const [customerTelephone, setCustomerTelephone] = useState('');
    const [createdDate, setCreatedDate] = useState()
    const [finishedDate, setFinishedDate] = useState()
    const [isDone, setIsDone] = useState(false);
    const navigate = useNavigate();
    useEffect(() => {
        const addingPrice = customerPrice - repairPrice
        setTotalPrice(addingPrice.toFixed(2));// Toplam Fiyatı iki ondalık basamağa yuvarla

    }, [repairPrice, customerPrice])

    useEffect(() => {
        getAllTelephoneModels()
        getById(deviceRepairId)  
    },[deviceRepairId])

   
    useEffect(()=> {
       if(deviceRepair){
        setCustomerName(deviceRepair.customerName)
        setCustomerTelephone(deviceRepair.customerTelephone)
        setDevicePassword(deviceRepair.devicePassword)
        setDescription(deviceRepair.description)
        setDeviceActions(deviceRepair.deviceActions)
        setCustomerPrice(deviceRepair.customerPrice)
        setRepairPrice(deviceRepair.repairPrice)
        const selected = models.find(
            (product) => product.telephoneModelId === deviceRepair.telephoneModelId
        );
        setSelectedModel(selected)
        if (deviceRepair.createdDate != null) {
            setCreatedDate(dayjs(deviceRepair.createdDate))
        }
        if (deviceRepair.finishedDate != null) {
            setFinishedDate(dayjs(deviceRepair.finishedDate))
        }
        setIsDone(deviceRepair.isDone)
       }
      
    },[deviceRepair,models])
    const getAllTelephoneModels = () => {
        getTelephoneModels()
            .then((data) => setModels(data))
            .catch((err) => console.log(err));
    };

    const handleModelChange = (newValue) => {
        setSelectedModel(newValue);
    };

    const handleCreatedDateChange = (date) => {
        setCreatedDate(date);
    };

    const handleFinishedDateChange = (date) => {
        setFinishedDate(date);
    };

    const handleIsDoneChange = (event) => {
        setIsDone(event.target.checked);
    };
    const getById = (id) => {
        getDeviceRepairById(id)
            .then((data) => {
                // Set the fetched data to the state
                setDeviceRepair(data);

            })
            .catch((err) => console.log(err));

    }
    const
     submitHandle = (e) => {
        e.preventDefault();

        const data = {
            deviceRepairId,
            description,
            customerName,
            customerTelephone,
            devicePassword,
            deviceActions,
            repairPrice,
            customerPrice,
            telephoneModelId : selectedModel.telephoneModelId,
            createdDate,
            finishedDate,
            isDone
        }


        updateDeviceRepair(data)
            .then((res) => {
                if (res.success === true) {
                    toast.success(res.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });

                    // 1,5 saniye sonra yönlendirme yap
                    setTimeout(() => {
                        navigate('/dashboard/devicerepairs');
                    }, 1500); // 1,5 saniye (1500 milisaniye) bekleyecek
                }
            })
            .catch((err) => console.log(err));
    }


    return (

        <>
            <Helmet>
                <title> Yeni Tamir  </title>
            </Helmet>
            <Container>
                <form onSubmit={submitHandle} >
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                        <Typography variant="h4" gutterBottom>
                            Tamir Edilecek Cihaz Ekleme
                        </Typography>

                    </Stack>
                    <Card>
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', margin: '20px' }}>

                        <TextField
                                id="outlined-multiline-static"
                                label="Açıklama"
                                multiline
                                rows={2}
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                sx={{ m: 1, width: '90ch' }}
                            />
                            
                            <Autocomplete
                                id="country-select-demo"
                                sx={{ m: 1, width: '30ch' }}
                                options={models}
                                autoHighlight
                                getOptionLabel={(option) => option.telephoneModelName}
                                
                                renderOption={(props, option) => (
                                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                        {option.telephoneModelName}
                                    </Box>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Model Seçiniz"
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: 'new-password', // disable autocomplete and autofill
                                        }}
                                    />
                                )}
                                value={selectedModel}
                                onChange={(event, newValue) => handleModelChange(newValue)}
                            />
                           
                                                    

                            <FormControl sx={{ m: 1,  width: '30ch' }}>
                                <TextField id="outlined-basic" label="Müşteri" variant="outlined" value={customerName} onChange={(e) => setCustomerName(e.target.value)} />
                            </FormControl>
                            <FormControl sx={{ m: 1, width: '30ch' }} >
                                <TextField id="outlined-basic" label="Müşteri Telefon" variant="outlined" value={customerTelephone} onChange={(e) => setCustomerTelephone(e.target.value)} inputProps={{
                                    maxLength: 11
                                }} />
                            </FormControl>
                            <FormControl sx={{ m: 1, width: '30ch' }} >
                                <TextField id="outlined-basic" label="Cihaz Şifresi" variant="outlined" value={devicePassword} onChange={(e) => setDevicePassword(e.target.value)} />
                            </FormControl>
                           
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={tr}>
                                <DemoContainer components={['DatePicker']}>
                                    <DatePicker label="Tarih" sx={{ marginLeft: '10px', width: '30ch' }} format="DD/MM/YYYY" value={createdDate} onChange={handleCreatedDateChange} />
                                </DemoContainer>
                            </LocalizationProvider>
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={tr}>
                                <DemoContainer components={['DatePicker']}>
                                    <DatePicker label="Bitiş Tarihi" sx={{ marginLeft: '10px', width: '30ch' }} format="DD/MM/YYYY" value={finishedDate} onChange={handleFinishedDateChange} />
                                </DemoContainer>
                            </LocalizationProvider>
                            <TextField
                                id="outlined-multiline-static"
                                label="Yapılan İşlemler"
                                multiline
                                rows={2}
                                value={deviceActions}
                                onChange={(e) => setDeviceActions(e.target.value)}
                                sx={{ m: 1, width: '90ch' }}
                            />
                             <FormControl sx={{ m: 1, marginTop: '15px', width: '30ch' }} >
                                <FormControlLabel control={<Checkbox checked={isDone} onChange={handleIsDoneChange} />} label="Tamir Tamamlandı" /></FormControl>
                        </Box>
                    </Card>
                    <br />
                    <Card>
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', margin: '20px' }}>

                            <div>
                            
                                <FormControl sx={{ m: 1, width: '30ch' }} variant="filled">
                                    <InputLabel htmlFor="filled-adornment-amount">Tamir Maaliyeti</InputLabel>
                                    <FilledInput
                                        id="filled-adornment-amount"
                                        startAdornment={<InputAdornment position="start">₺</InputAdornment>}
                                        value={repairPrice}
                                        onChange={(e) => setRepairPrice(e.target.value)}
                                    />
                                </FormControl>

                                <FormControl sx={{ m: 1, width: '30ch' }} variant="filled">
                                    <InputLabel htmlFor="filled-adornment-amount">Müşteri Fiyatı</InputLabel>
                                    <FilledInput
                                        id="filled-adornment-amount"
                                        startAdornment={<InputAdornment position="start">₺</InputAdornment>}
                                        value={customerPrice}
                                        onChange={(e) => setCustomerPrice(e.target.value)}
                                    />
                                </FormControl>

                            </div>
                            <div>
                                <FormControl sx={{ m: 1, width: '50ch' }} variant="standard">
                                    <InputLabel htmlFor="standard-adornment-amount">Kâr</InputLabel>
                                    <Input
                                        readOnly
                                        id="standard-adornment-amount"
                                        startAdornment={<InputAdornment position="start">₺</InputAdornment>}
                                        value={totalPrice === '' ? '0' : totalPrice}
                                        onChange={(e) => setTotalPrice(e.target.value)}
                                    />
                                </FormControl>
                            </div>
                        </Box>
                    </Card>                <ToastContainer />
                    <Button
                        sx={{ m: 3 }}
                        color="primary"
                        variant="contained"
                        type='submit'
                    >
                        Güncelle
                    </Button>
                    <hr />
                    <List sx={{ m: 1 }}>
                        <ListItem>
                            <ListItemText primary="Alış Fiyatı" secondary={`${repairPrice} ₺`} />
                            <ListItemText primary="Satış Fiyatı" secondary={`${customerPrice} ₺`} />
                            <ListItemText primary="Kâr" secondary={`${totalPrice} ₺`} />
                        </ListItem>
                    </List>
                </form>
            </Container >
        </>
    );
}