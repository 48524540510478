import {post,get} from './request'

export const getTelephones = () => get('/Telephones/getall')
export const getTelephoneWithDetails = () => get('/Telephones/getallwithdetail')
export const getTelephoneWithDetailsDaily = () => get('/Telephones/getallwithdetailtoday')
export const getTelephoneFinishedCount = () => get('/Telephones/totalfinishedtelephone')
export const getTelephoneWaitingCount = () => get('/Telephones/totalwaitingtelephone')
export const getTelephoneById = id => get(`/Telephones/getbyid?telephoneId=${id}`)
export const deleteTelephone = id => post(`/Telephones/delete/${id}`)
export const updateTelephoneStatus = id => post(`/Telephones/updatetelephonestatus/${id}`)
export const addTelephone = data => post('/Telephones/add',data)
export const updateTelephone = data => post('/Telephones/update',data)