
import { Helmet } from 'react-helmet-async';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DailySaleReport from './DailySaleReport'
import DailyTelephoneReport from './DailyTelephoneReport'
// @mui

// ----------------------------------------------------------------------

export default function TelephoneMaterialSaleList() {

    return (
        <>
            <ToastContainer />
            <Helmet>
                <title> Günlük Raporlar </title>
            </Helmet>
            <DailySaleReport />
            <hr/>
            <DailyTelephoneReport />
        </>
    );
}
