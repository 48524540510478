import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

const LoginGuard = () => {
  const [authenticated, setAuthenticated] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const checkToken = async () => {
      const token = localStorage.getItem('jwt');
      const expiration = localStorage.getItem('expiration');

      if (token && expiration) {
        const currentTimestamp = new Date().getTime();
        const expirationTimestamp = new Date(expiration).getTime();

        if (currentTimestamp < expirationTimestamp) {
          setAuthenticated(true);
        } else {
          setAuthenticated(false);
          navigate('/login');
          localStorage.removeItem("jwt");
          localStorage.removeItem("expiration");
        }
      } else {
        setAuthenticated(false);
        navigate('/login');
        localStorage.removeItem("jwt");
        localStorage.removeItem("expiration");
      }
    };

    checkToken();
  }, [navigate]);

  if (!authenticated) {
    return null;
  }

  return <Outlet />; // Use Outlet to render child routes
};

export default LoginGuard;