import {post,get} from './request'

export const getDeviceRepairs = () => get('/DeviceRepairs/getall')
export const getDeviceRepairTotalEarnig = () => get('/DeviceRepairs/totalearning')
export const getDeviceRepairTotalExpense = () => get('/DeviceRepairs/totalexpense')
export const getDeviceRepairYearlyCount = () => get('/DeviceRepairs/yearlysales')
export const getWaitingDeviceRepair = () => get('/DeviceRepairs/totalwaitingtelephone')
export const getFinishedDeviceRepair = () => get('/DeviceRepairs/totalfinishedtelephone')
export const getDeviceRepairsDetail = () => get('/DeviceRepairs/getlistdetail')
export const getDeviceRepairsDetailDaily = () => get('/DeviceRepairs/getlistdetaildaily')
export const getDeviceRepairById = id => get(`/DeviceRepairs/getbyid?deviceRepairId=${id}`)
export const deleteDeviceRepair = id => post(`/DeviceRepairs/delete/${id}`)
export const updateDeviceRepairStatus = id => post(`/DeviceRepairs/updateDeviceRepairStatus/${id}`)
export const addDeviceRepair = data => post('/DeviceRepairs/add',data)
export const updateDeviceRepair = data => post('/DeviceRepairs/update',data)