import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// @mui
import {
    Card,
    Table,
    Paper,
    Stack,
    Checkbox,
    TableRow,
    TableBody,
    TableCell,

    Typography,
    IconButton,
    TableContainer,
    TablePagination,
    Button,
    Divider,
    Popover,
    MenuItem,

} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';

import Label from '../../../components/label'; // Eğer 'components' klasörü 'telephoneCategory' klasörüne üst düzeyde ise
import Iconify from '../../../components/iconify';
// components

import Scrollbar from '../../../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../../../sections/@dashboard/user';
import { getOlDevicesList, deleteOldDevice, updateOldDeviceStatus } from '../../../services';




// ----------------------------------------------------------------------

const TABLE_HEAD = [
    { id: 'telephoneModelName', label: 'Model', alignRight: false },
    { id: 'imeiNo', label: 'IMEI', alignRight: false },
    { id: 'color', label: 'Renk', alignRight: false },
    { id: 'deviceMemory', label: 'GB', alignRight: false },
    { id: 'guranteeTerm', label: 'Garanti', alignRight: false },
    { id: 'buyingPrice', label: 'Alış Fiyatı', alignRight: false },
    { id: 'sellingPrice', label: 'Satış Fiyatı', alignRight: false },
    { id: 'earning', label: 'Kâr', alignRight: false },
    { id: 'sellingCustomer', label: 'Satın Alan', alignRight: false },
    { id: 'buyingCustomerName', label: 'Satan', alignRight: false },
    { id: 'deviceCategory', label: 'Türü', alignRight: false },
    { id: 'sellingDate', label: 'Satış Tarihi', alignRight: false },
    { id: '', label: 'İşlemler', alignRight: false },
];

// ----------------------------------------------------------------------
function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(
            array,
            (telephonecategory) => telephonecategory.telephoneModelName.toLowerCase().indexOf(query.toLowerCase()) !== -1
        );
    }
    return stabilizedThis.map((el) => el[0]);
}



export default function SoldOldDevicesList() {

    const [page, setPage] = useState(0);
    const [order, setOrder] = useState('asc');
    const [selected, setSelected] = useState([]);
    const [orderBy, setOrderBy] = useState('name');
    const [filterName, setFilterName] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [telephonecategories, setTelephoneCategories] = useState([]);
    const navigate = useNavigate();
    const [open, setOpen] = useState(null);
    const [anchorId, setAnchorId] = useState(null);
    const handleOpenMenu = (event, oldDeviceId) => {
        setOpen(event.currentTarget);
        setAnchorId(oldDeviceId);
    };
    const handleCloseMenu = () => {
        setOpen(null);
    };
    useEffect(() => {
        getAll();
    }, []);

    const getAll = () => {
        getOlDevicesList()
            .then((data) => setTelephoneCategories(data))
            .catch((err) => console.log(err));

    };

    function formatDate(dateString) {
        // Tarih dizesini JavaScript Date nesnesine dönüştür
        const date = new Date(dateString);

        // Tarih nesnesini istediğiniz formata biçimlendirin (örneğin: yyyy-MM-dd)
        const formattedDate = date.toLocaleDateString('tr-TR', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
        });

        return formattedDate;
    }



    const deleteCategory = (id) => {
        const confirmDelete = window.confirm('Ürünü Silmek İstediğinizi Emin Misiniz ? Bu İşlem Geri Alınamaz');
        if (confirmDelete) {
            deleteOldDevice(id)
                .then((res) => {
                    if (res.success === true) {
                        toast.success(res.message, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        });
                        getAll();
                        handleCloseMenu()
                    }
                })
                .catch((err) => {
                    toast.error(err.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                });
        }
    };

    const updateDeviceStatus = (id) => {
        const confirmUpdate = window.confirm('Ürünü İadet Etmek İstediğinizi Emin Misiniz ?');
        if (confirmUpdate) {
            updateOldDeviceStatus(id)
                .then((res) => {
                    if (res.success === true) {
                        toast.success(res.message, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        });
                    }
                    getAll();
                    handleCloseMenu()
                })
                .catch((err) => {
                    toast.error(err.message, {
                        position: toast.POSITION.BOTTOM_RIGHT
                    })
                });
        }

    };
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = telephonecategories.map((n) => n.telephoneMaterialName);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const handleFilterByName = (event) => {
        setPage(0);
        const query = event.target.value.toLowerCase(); // Arama sorgusunu küçük harfe çevirin
        setFilterName(query);
    };


    function formatNumberWithCommas(number) {
        const formatter = new Intl.NumberFormat('tr-TR');
        return formatter.format(number);
    }

    const filteredUsers = applySortFilter(telephonecategories, getComparator(order, orderBy), filterName);
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - telephonecategories.length) : 0;
    const isNotFound = !filteredUsers.length && !!filterName;

    return (
        <>
            <ToastContainer />
            <Helmet>
                <title> Satılan Cihazlar </title>
            </Helmet>


            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                <Typography variant="h4" gutterBottom>
                    Satılan Cihazlar
                </Typography>
                <Link to="/dashboard/olddeviceadd">
                    <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
                        Yeni Ekle
                    </Button>
                </Link>
            </Stack>

            <Card>
                <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />
                <Scrollbar>
                    <TableContainer sx={{ minWidth: 800 }}>
                        <Table>
                            <UserListHead
                                order={order}
                                orderBy={orderBy}
                                headLabel={TABLE_HEAD}
                                rowCount={telephonecategories.length}
                                numSelected={selected.length}
                                onRequestSort={handleRequestSort}
                                onSelectAllClick={handleSelectAllClick}
                            />

                            <TableBody>
                                {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                    const { oldDeviceId, telephoneModelName, imeiNo, color, deviceMemory, guaranteeTerm, buyingPrice, salePrice, sellingCustomerName, sellingDate,buyingCustomerName,deviceCategory } = row;
                                    const selectedUser = selected.indexOf(oldDeviceId) !== -1;

                                    return (
                                        <TableRow hover key={oldDeviceId} tabIndex={-1} role="checkbox" selected={selectedUser}>
                                            <TableCell padding="checkbox">
                                                <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, oldDeviceId)} />
                                            </TableCell>

                                            <TableCell align="left"><Label color="primary">{telephoneModelName}</Label></TableCell>
                                            <TableCell align="left">
                                                <Label color="warning">{imeiNo}</Label>
                                            </TableCell>
                                            <TableCell align="left">
                                                <Label color="warning">{color}</Label>
                                            </TableCell>
                                            <TableCell align="left">
                                                <Label color="warning">{deviceMemory}</Label>
                                            </TableCell>
                                            <TableCell align="left">
                                                <Label color="default">
                                                    {guaranteeTerm ? formatDate(guaranteeTerm) : 'Yok'}
                                                </Label>
                                            </TableCell>
                                            <TableCell align="left" ><Label color="error">{formatNumberWithCommas(buyingPrice)} ₺</Label></TableCell>

                                            <TableCell align="left" ><Label color="info">{formatNumberWithCommas(salePrice)} ₺</Label></TableCell>
                                            <TableCell align="left" ><Label color="success">{formatNumberWithCommas(salePrice - buyingPrice)} ₺</Label></TableCell>
                                            <TableCell align="left">
                                                <Label color="warning">{sellingCustomerName}</Label>
                                            </TableCell>
                                            <TableCell align="left">
                                                <Label color="warning">{buyingCustomerName}</Label>
                                            </TableCell>
                                            <TableCell align="left">
                                                <Label color="info">{deviceCategory}</Label>
                                            </TableCell>
                                            <TableCell align="left"><Label color="default">{formatDate(sellingDate)}</Label></TableCell>
                                            <TableCell>
                                                <IconButton size="large" color="inherit" sx={{ opacity: 0.48 }} onClick={(event) => handleOpenMenu(event, oldDeviceId)}>
                                                    <Iconify icon={'eva:more-vertical-fill'} />
                                                </IconButton>
                                                <Popover

                                                    open={Boolean(open)}
                                                    anchorEl={open}
                                                    onClose={handleCloseMenu}
                                                    anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                                                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                                                    PaperProps={{
                                                        sx: {
                                                            p: 1,
                                                            '& .MuiMenuItem-root': {
                                                                px: 1,
                                                                typography: 'body2',
                                                                borderRadius: 0.75,
                                                            },
                                                        },
                                                    }}
                                                >

                                                    <MenuItem sx={{ color: 'info.main' }} onClick={() => navigate(`/dashboard/olddeviceupdate/edit/${anchorId}`)}>
                                                        <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
                                                        Güncelle
                                                    </MenuItem>
                                                    <MenuItem sx={{ color: 'default.main' }} onClick={() => updateDeviceStatus(anchorId)}>
                                                        <Iconify icon={'eva:corner-down-left-outline'} sx={{ mr: 2 }}  />
                                                        İade Yap
                                                    </MenuItem>
                                                    <Divider sx={{ borderStyle: 'dashed' }} />

                                                    <MenuItem sx={{ color: 'error.main' }} onClick={() => deleteCategory(anchorId)}>
                                                        <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
                                                        Sil
                                                    </MenuItem>
                                                </Popover>


                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                                {emptyRows > 0 && (
                                    <TableRow style={{ height: 53 * emptyRows }}>
                                        <TableCell colSpan={6} />
                                    </TableRow>
                                )}
                            </TableBody>
                            {isNotFound && (
                                <TableBody>
                                    <TableRow>
                                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                            <Paper
                                                sx={{
                                                    textAlign: 'center',
                                                }}
                                            >
                                                <Typography variant="h6" paragraph>
                                                    Sonuç Bulunamadı
                                                </Typography>

                                                <Typography variant="body2">
                                                    aramak istediğiniz &nbsp;
                                                    <strong>&quot;{filterName}&quot;</strong>.
                                                    <br /> Aradığınız kelimelere uygun sonuç bulunmamaktadır.
                                                </Typography>
                                            </Paper>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            )}
                        </Table>
                    </TableContainer>
                </Scrollbar>

                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={telephonecategories.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Card>

        </>
    );
}
