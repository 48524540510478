// component



import DashboardIcon from '@mui/icons-material/Dashboard';
import CategoryIcon from '@mui/icons-material/Category';
import AppSettingsAltIcon from '@mui/icons-material/AppSettingsAlt'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'
import InventoryIcon from '@mui/icons-material/Inventory'
import ReceiptIcon from '@mui/icons-material/Receipt';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import ChecklistIcon from '@mui/icons-material/Checklist';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import AdUnitsIcon from '@mui/icons-material/AdUnits';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import SellIcon from '@mui/icons-material/Sell';
import SettingsIcon from '@mui/icons-material/Settings';
// ----------------------------------------------------------------------

// const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'Ana Sayfa',
    path: '/dashboard/app',
    icon:<DashboardIcon sx={{ width: 1, height: 1 }} />,
  },
  {
    title: 'Tamirler',
    path: '/dashboard/devicerepairs',
    icon: <SettingsIcon sx={{ width: 1, height: 1 }} />,
  },
  {
    title: 'Malzeme Satışı',
    path: '/dashboard/telephonematerialsales',
    icon: <TrendingUpIcon sx={{ width: 1, height: 1 }} />,
  },
  {
    title: 'Malzemeler',
    path: '/dashboard/telephonematerials',
    icon: <ShoppingBagIcon sx={{ width: 1, height: 1 }} />,
  },
  {
    title: 'Malzeme Stokları',
    path: '/dashboard/telephonematerialstocks',
    icon: <ChecklistIcon sx={{ width: 1, height: 1 }} />,
  },
  {
    title: 'Malzeme Ücretleri',
    path: '/dashboard/telephonematerialprices',
    icon: <PriceChangeIcon sx={{ width: 1, height: 1 }} />,
  },
  {
    title: 'Cihazlar',
    path: '/dashboard/olddevices',
    icon: <AdUnitsIcon sx={{ width: 1, height: 1 }} />,
  },
  {
    title: 'Satılan Cihazlar',
    path: '/dashboard/soldolddevices',
    icon: <SellIcon sx={{ width: 1, height: 1 }} />,
  },
  {
    title: 'Tamir Kategorileri',
    path: '/dashboard/telephonecategories',
    icon: <CategoryIcon sx={{ width: 1, height: 1 }} />,
  },
  {
    title: 'Telefon Modelleri',
    path: '/dashboard/telephonemodels',
    icon: <AppSettingsAltIcon sx={{ width: 1, height: 1 }} />,
  },
  {
    title: 'Telefon Ustaları',
    path: '/dashboard/telephonemasters',
    icon: <ManageAccountsIcon sx={{ width: 1, height: 1 }} />,
  },
  {
    title: 'Malzeme Kategorileri',
    path: '/dashboard/telephonematerialcategories',
    icon: <InventoryIcon sx={{ width: 1, height: 1 }} />,
  },
  {
    title: 'Müşteri Tipleri',
    path: '/dashboard/telephonematerialpricecategories',
    icon: <ReceiptIcon sx={{ width: 1, height: 1 }} />,
  },
  {
    title: 'Günlük Raporlar',
    path: '/dashboard/dailyreports',
    icon: <AnalyticsIcon sx={{ width: 1, height: 1 }} />,
  },
  
  
];

export default navConfig;
