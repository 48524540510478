import * as React from 'react';
import { useState, useEffect, useCallback } from 'react';
import { Helmet } from 'react-helmet-async';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    Card,
    Container,
    Stack,
    Typography,
    Autocomplete,
    Box,
    InputLabel,
    FilledInput,
    FormControl,
    TextField,
    Button,

} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { getTelephoneMaterials, addTelephoneMaterialStock,addTelephoneMaterialStockMovement } from '../../../services';



export default function TelephoneMaterialStockAdd() {
    const [telephonematerials, setTelephoneMaterials] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [increasingStock,setIncreasingStock] = useState('0');
    const [decreasingStock,setDecreasingStock] = useState('0');
    const [totalStock,setTotalStock] = useState('0');
    const [materialStockId, setTelephoneMaterialStockId] = useState(null);
    const navigate = useNavigate();
    const [value, setValue] = useState('1');

    useEffect(() => {
        
        getAllTelephoneMaterials();
        
    }, [])

    useEffect(() => {             
        const toplamStock = increasingStock - decreasingStock
        setTotalStock(toplamStock);
    }, [decreasingStock,increasingStock])

    const getAllTelephoneMaterials = () => {
        getTelephoneMaterials()
            .then((data) => setTelephoneMaterials(data))
            .catch((err) => console.log(err));

    };

    const submitHandle = (e) => {
        e.preventDefault();
        const data = {
            telephoneMaterialId: selectedProduct.telephoneMaterialId,
        };

        addTelephoneMaterialStock(data)
            .then((res) => {
                if (res.success === true) {
                    toast.success(res.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                    setTelephoneMaterialStockId(res.data.telephoneMaterialStockId);                                   
                    setValue('2');
                }
            })
            .catch((err) => {
                toast.error(err.message, {
                    position: toast.POSITION.BOTTOM_RIGHT
                })
            });
    }
    
    const submitHandleForStockMovement = (e) => {
        e.preventDefault();
        const data = {
            telephoneMaterialId: selectedProduct.telephoneMaterialId,
            telephoneMaterialStockQuantityIncreasing:increasingStock,
            telephoneMaterialStockQuantityDecreasing:decreasingStock,
            telephoneMaterialStockQuantity:totalStock,
            telephoneMaterialStockId:materialStockId
        };

        addTelephoneMaterialStockMovement(data)
            .then((res) => {
                if (res.success === true) {
                    toast.success(res.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                    setTimeout(() => {
                        navigate('/dashboard/telephonematerialstocks');
                    }, 1500); // 1,5 saniye (1500 milisaniye) bekleyecek
                }
            })
            .catch((err) => {
                toast.error(err.message, {
                    position: toast.POSITION.BOTTOM_RIGHT
                })
            });
    }

    return (

        <>
            <Helmet>
                <title> Yeni Malzeme Stoğu </title>
            </Helmet>
            <Box sx={{ width: '100%', typography: 'body1' }}>
                <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList aria-label="lab API tabs example">
                            <Tab label="Ürün Seçimi" value="1" />
                            <Tab label="Stok Yönetimi" value="2" />
                        </TabList>
                    </Box>
                    <TabPanel value="1"> <Container>
                        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                            <Typography variant="h4" gutterBottom>
                                Yeni Malzeme Stoğu
                            </Typography>

                        </Stack>
                        <Card>
                            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                                <Typography variant="h4" gutterBottom sx={{ display: 'flex', flexWrap: 'wrap', margin: '20px' }}>
                                    Lütfen Ürün Seçiniz
                                </Typography>

                            </Stack>
                            <form onSubmit={submitHandle}>
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', margin: '20px' }}>
                                    <div style={{ display: 'flex', gap: '16px' }}>
                                        <FormControl>
                                            <Autocomplete
                                                id="country-select-demo"
                                                sx={{ m: 1, width: '50ch' }}
                                                options={telephonematerials}
                                                autoHighlight
                                                getOptionLabel={(option) => option.telephoneMaterialName}
                                                isOptionEqualToValue={useCallback((option, value) => option.value === value.value, [])}
                                                renderOption={(props, option) => (
                                                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>

                                                        {option.telephoneMaterialName}
                                                    </Box>
                                                )}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Ürün Seçiniz"
                                                        inputProps={{
                                                            ...params.inputProps,
                                                            autoComplete: 'new-password', // disable autocomplete and autofill
                                                        }}
                                                    />
                                                )}
                                                value={selectedProduct}
                                                onChange={(event, newValue) => {
                                                    setSelectedProduct(newValue);
                                                }}
                                            />
                                        </FormControl>
                                    </div>
                                
                                </Box>
                                <Button
                                    sx={{ m: 3 }}
                                    color="primary"
                                    variant="contained"

                                    type='submit'>
                                    Devam Et
                                </Button>
                            </form>
                        </Card>
                    </Container></TabPanel>

                    <TabPanel value="2"><Container>
                        <Card>
                            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                                <Typography variant="h4" gutterBottom sx={{ display: 'flex', flexWrap: 'wrap', margin: '20px' }}>
                                    Lütfen Stok Bilgilerini Giriniz
                                </Typography>

                            </Stack>
                            <form onSubmit={submitHandleForStockMovement}>
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', margin: '20px' }}>
                                    <div style={{ display: 'flex', gap: '16px' }}>
                                    <FormControl sx={{ m: 1, width: '30ch' }} variant="filled">
                                    <InputLabel htmlFor="filled-adornment-amount">Eklenecek Stok (Adet)</InputLabel>
                                    <FilledInput
                                        id="filled-adornment-amount"
                                      
                                        value={increasingStock}
                                        onChange={(e) => setIncreasingStock(e.target.value)}                                                                            
                                    />
                                </FormControl>
                                <FormControl sx={{ m: 1, width: '30ch' }} variant="filled">
                                    <InputLabel htmlFor="filled-adornment-amount">Çıkarılacak Stok (Adet)</InputLabel>
                                    <FilledInput
                                        id="filled-adornment-amount"
                                 
                                        value={decreasingStock}
                                        onChange={(e) => setDecreasingStock(e.target.value)}                                                                                  
                                    />
                                </FormControl>
                                <FormControl sx={{ m: 1, width: '30ch' }} variant="filled">
                                    <InputLabel htmlFor="filled-adornment-amount">Toplam Stok</InputLabel>
                                    <FilledInput
                                        id="filled-adornment-amount"
                                        readOnly
                                        value={totalStock}
                                        onChange={(e) => setTotalStock(e.target.value)}     
                                    />
                                </FormControl>
                                    </div>
                                
                                </Box>
                                <Button
                                    sx={{ m: 3 }}
                                    color="primary"
                                    variant="contained"

                                    type='submit'>
                                   Kaydet
                                </Button>
                            </form>                         
                        </Card></Container>  </TabPanel>
                                            
                </TabContext>
                <ToastContainer />  
            </Box>

        </>
    );
}