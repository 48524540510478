import {post,get} from './request'
import { postMultipart } from './postMultipart'

export const getOlDevices = () => get('/OldDevices/getall')
export const getOlDevicesEarning = () => get('/OldDevices/totalearning')
export const getOlDevicesExpense = () => get('/OldDevices/totalexpense')
export const getOldDeviceYearlyCount = () => get('/OldDevices/yearlysales')
export const getSoldOldDevices = () => get('/OldDevices/totalsoldtelephone')
export const getOlDevicesView = () => get('/OldDevices/getviewdetail')
export const getOlDevicesList = () => get('/OldDevices/getlistdetail')
export const getOldDevicById = id => get(`/OldDevices/getbyid?oldDeviceId=${id}`)
export const updateOldDeviceStatus = id => post(`/OldDevices/updateOldDeviceStatus/${id}`)
export const deleteOldDevice = id => post(`/OldDevices/delete/${id}`)
export const addOldDevice = data => postMultipart('/OldDevices/add',data)
export const updateOldDevice = data => postMultipart('/OldDevices/update',data)