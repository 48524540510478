import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// @mui
import {
    Card,
    Table,
    Paper,
    Stack,
    Checkbox,
    TableRow,
    TableBody,
    TableCell,
    
    Typography,
    IconButton,
    TableContainer,
    TablePagination,
    Button,

} from '@mui/material';
import { Link } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Label from '../../../components/label'; // Eğer 'components' klasörü 'telephoneCategory' klasörüne üst düzeyde ise
import Iconify from '../../../components/iconify';
// components

import Scrollbar from '../../../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../../../sections/@dashboard/user';
import { getTelephoneMaterialsWithDetails, deleteTelephoneMaterial } from '../../../services';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
    { id: 'telephoneMaterialName', label: 'Malzeme', alignRight: false },
    { id: 'telephoneMaterialPriceCategoryName', label: 'Kategori', alignRight: false },
    { id: 'telephoneMaterialPurchasePrice', label: 'Alış Fiyatı', alignRight: false },
    { id: 'telephoneMaterialTotalPrice', label: 'Stok Kartı', alignRight: false },
    { id: 'telephoneMaterialVatRate', label: 'Stok Durumu', alignRight: false },
    { id: '', label: 'İşlemler', alignRight: false },
];

// ----------------------------------------------------------------------
function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(
            array,
            (telephonecategory) => telephonecategory.telephoneMaterialName.toLowerCase().indexOf(query.toLowerCase()) !== -1
        );
    }
    return stabilizedThis.map((el) => el[0]);
}



export default function TelephoneMaterialList() {
    const [page, setPage] = useState(0);
    const [order, setOrder] = useState('asc');
    const [selected, setSelected] = useState([]);
    const [orderBy, setOrderBy] = useState('name');
    const [filterName, setFilterName] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [telephonecategories, setTelephoneCategories] = useState([]);


    useEffect(() => {
        getAll();
    }, []);

    const getAll = () => {
        getTelephoneMaterialsWithDetails()
            .then((data) => setTelephoneCategories(data))
            .catch((err) => console.log(err));

    };

    

    const deleteCategory = (id) => {
        deleteTelephoneMaterial(id)
            .then((res) => {
                if (res.success === true) {
                    toast.success(res.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                }
                getAll();
            })
            .catch((err) => {
                toast.error(err.message, {
                    position: toast.POSITION.BOTTOM_RIGHT
                })
            });
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = telephonecategories.map((n) => n.telephoneMaterialName);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const handleFilterByName = (event) => {
        setPage(0);
        const query = event.target.value.toLowerCase(); // Arama sorgusunu küçük harfe çevirin
        setFilterName(query);
    };

    const filteredUsers = applySortFilter(telephonecategories, getComparator(order, orderBy), filterName);
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - telephonecategories.length) : 0;
    const isNotFound = !filteredUsers.length && !!filterName;

    return (
        <>
            <ToastContainer />
            <Helmet>
                <title> Mataryeller </title>
            </Helmet>

            
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Mataryeller
                    </Typography>
                    <Link to="/dashboard/telephonematerialadd">
                        <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
                            Yeni Ekle
                        </Button>
                    </Link>
                </Stack>

                <Card>
                    <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />
                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 800 }}>
                            <Table>
                                <UserListHead
                                    order={order}
                                    orderBy={orderBy}
                                    headLabel={TABLE_HEAD}
                                    rowCount={telephonecategories.length}
                                    numSelected={selected.length}
                                    onRequestSort={handleRequestSort}
                                    onSelectAllClick={handleSelectAllClick}
                                />

                                <TableBody>
                                    {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                        const { telephoneMaterialId, telephoneMaterialStockId, totalStock, telephoneMaterialName, telephoneMaterialCategoryName,purchasePrice } = row;
                                        const selectedUser = selected.indexOf(telephoneMaterialName) !== -1;

                                        return (
                                            <TableRow hover key={telephoneMaterialId} tabIndex={-1} role="checkbox" selected={selectedUser}>
                                                <TableCell padding="checkbox">
                                                    <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, telephoneMaterialName)} />
                                                </TableCell>

                                                <TableCell align="left"><Label color="default">{telephoneMaterialName}</Label></TableCell>
                                                <TableCell align="left">
                                                    <Label color="primary">{telephoneMaterialCategoryName}</Label>
                                                </TableCell>
                                                <TableCell align="left">
                                                    <Label color="primary">{purchasePrice} ₺</Label>
                                                </TableCell>
                                                <TableCell align="left">
                                                    <Label color={telephoneMaterialStockId ? "success" : "error"}>
                                                        {telephoneMaterialStockId ? "Mevcut" : "Yok"}
                                                    </Label>
                                                </TableCell>


                                                <TableCell align="left">
                                                    {totalStock < 0 ? (
                                                        <Label color="error">{totalStock}</Label>
                                                    ) : totalStock < 6 ? (
                                                        <Label color="warning">{totalStock}</Label>
                                                    ) : (
                                                        <Label color="success">{totalStock}</Label>
                                                    )}
                                                </TableCell>

                                               

                                                <TableCell>
                                                    <IconButton aria-label="delete" color="error" onClick={() => deleteCategory(telephoneMaterialId)} >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                    <IconButton
                                                        aria-label="edit"
                                                        color="primary"
                                                       
                                                    >
                                                        <EditIcon />
                                                    </IconButton>

                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                    {emptyRows > 0 && (
                                        <TableRow style={{ height: 53 * emptyRows }}>
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>
                                {isNotFound && (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                                <Paper
                                                    sx={{
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    <Typography variant="h6" paragraph>
                                                        Sonuç Bulunamadı
                                                    </Typography>

                                                    <Typography variant="body2">
                                                        aramak istediğiniz &nbsp;
                                                        <strong>&quot;{filterName}&quot;</strong>.
                                                        <br /> Aradığınız kelimelere uygun sonuç bulunmamaktadır.
                                                    </Typography>
                                                </Paper>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </Scrollbar>

                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={telephonecategories.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Card>
           
        </>
    );
}
