import * as React from 'react';
import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    Card,
    Container,
    Stack,
    Typography,
    Autocomplete,
    Box,
    Input,
    FilledInput,
    InputLabel,
    InputAdornment,
    FormControl,
    TextField,
    Button,
    ListItem,
    ListItemText,
    List,

} from '@mui/material';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { getTelephoneMaterialCategories, addTelephoneMaterial, addTelephoneMaterialStockMovement, addTelephoneMaterialStock, addTelephoneMaterialPrice, getTelephoneMaterialPriceCategories } from '../../../services';



export default function TelephoneMaterialAdd() {
    const [telephonematerialpricecategories, setTelephoneMaterialPriceCategories] = useState([]);
    const [pricecategories, setPriceCategories] = useState([])
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedPriceCategory, setSelectedPriceCategory] = useState(null)
    const [materialName, setMaterialName] = useState('')   
    const [increasingStock, setIncreasingStock] = useState('0');
    const [decreasingStock, setDecreasingStock] = useState('0');
    const [totalStock, setTotalStock] = useState('0');
    const [materialId, setTelephoneMaterialId] = useState(null);
    const [stockId, setStockId] = useState(null)
    const [price, setPrice] = useState('0');
    const [kdvRate, setKdvRate] = useState('0');
    const [discount, setDiscount] = useState('0');
    const [totalPrice, setTotalPrice] = useState('0');
    const [value, setValue] = useState('1');
    const [purchasePrice,setPurchasePrice] = useState('0')
    const navigate = useNavigate();

    useEffect(() => {
        const vergimiktari = price * (kdvRate / 100);
        const vergiliTotal = vergimiktari + parseFloat(price);
        const indirimtotali = parseFloat(discount);

        // Toplam Fiyatı hesapla
        const indirimliTotal = vergiliTotal - indirimtotali;

        const toplamStok = increasingStock - decreasingStock
        setTotalStock(toplamStok)
        setTotalPrice(indirimliTotal.toFixed(2));
        getAllTelephoneMaterialPriceCategories();
        PriceCategories();
     
    }, [discount, kdvRate, price, decreasingStock, increasingStock])

    const PriceCategories = () => {
        getTelephoneMaterialPriceCategories()
            .then((data) => setPriceCategories(data))
            .catch((err) => console.log(err));

    };

    const getAllTelephoneMaterialPriceCategories = () => {
        getTelephoneMaterialCategories()
            .then((data) => setTelephoneMaterialPriceCategories(data))
            .catch((err) => console.log(err));

    };

    const submitHandle = (e) => {
        e.preventDefault();
        const data = {
            telephoneMaterialName: materialName,
            telephoneMaterialCategoryId: selectedCategory.telephoneMaterialCategoryId,
            purchasePrice
        };

        addTelephoneMaterial(data)
            .then((res) => {
                if (res.success === true) {
                    toast.success(res.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                    setTelephoneMaterialId(res.data.telephoneMaterialId)

                }
            })
            .catch((err) => console.log(err));
    }

    useEffect(() => {
        if (materialId !== null) {
            const data2 = {
                telephoneMaterialId: materialId
            };
            addTelephoneMaterialStock(data2)
                .then((res) => {
                    if (res.success === true) {
                        setStockId(res.data.telephoneMaterialStockId);                     
                        setValue('2');
                    }
                })
                .catch((err) => {
                    toast.error(err.message, {
                        position: toast.POSITION.BOTTOM_RIGHT
                    });
                });
        }
    }, [materialId]);

    const submitHandleForStockMovement = (e) => {
        e.preventDefault();
        const data = {
            telephoneMaterialId: materialId,
            telephoneMaterialStockQuantityIncreasing: increasingStock,
            telephoneMaterialStockQuantityDecreasing: decreasingStock,
            telephoneMaterialStockQuantity: totalStock,
            telephoneMaterialStockId: stockId,
        };

        addTelephoneMaterialStockMovement(data)
            .then((res) => {
                if (res.success === true) {
                    toast.success(res.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                }
            })
            .catch((err) => {
                toast.error(err.message, {
                    position: toast.POSITION.BOTTOM_RIGHT
                })
            });

        const data3 = {
            telephoneMaterialId: materialId,
            telephoneMaterialPriceCategoryId: selectedPriceCategory.telephoneMaterialPriceCategoryId,
            telephoneMaterialTotalPrice: price,
            telephoneMaterialDiscountRate: discount,
            telephoneMaterialVatRate: kdvRate,
            telephoneMaterialSubTotal: totalPrice,
        };

        addTelephoneMaterialPrice(data3)
            .then((res) => {
                if (res.success === true) {
                    toast.dismiss();
                    toast.success(res.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });

                    // 1,5 saniye sonra yönlendirme yap
                    setTimeout(() => {
                        navigate('/dashboard/telephonematerials');
                    }, 1500); // 1,5 saniye (1500 milisaniye) bekleyecek
                }
            })
            .catch((err) => console.log(err));
    }

    return (

        <>
            <Helmet>
                <title> Yeni Ürün </title>
            </Helmet>
            <TabContext value={value}>
                <Container>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                        <Typography variant="h4" gutterBottom>
                            Yeni Ürün : {materialName}
                        </Typography>

                    </Stack>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList aria-label="lab API tabs example">
                            <Tab label="Yeni Ürün" value="1" />
                            <Tab label="Stok ve Ücret" value="2" />
                        </TabList>
                    </Box>
                    <TabPanel value="1">
                        <Card>
                            <form onSubmit={submitHandle}>
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', margin: '20px' }}>
                                    <div style={{ display: 'flex', gap: '16px' }}>
                                        <FormControl>
                                            <TextField id="outlined-basic" label="Ürün İsmi" variant="outlined" sx={{ m: 1, width: '30ch' }} value={materialName}
                                                onChange={(e) => setMaterialName(e.target.value)} />

                                        </FormControl>
                                        <FormControl>
                                            <Autocomplete
                                                id="country-select-demo"
                                                sx={{ m: 1, width: '30ch' }}
                                                options={telephonematerialpricecategories}
                                                autoHighlight
                                                value={selectedCategory}
                                                getOptionLabel={(option) => option.telephoneMaterialCategoryName}
                                                isOptionEqualToValue={useCallback((option, value) => option.value === value.value, [])}
                                                renderOption={(props, option) => (
                                                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>

                                                        {option.telephoneMaterialCategoryName}
                                                    </Box>
                                                )}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Ürün Kategorisi Seçiniz"
                                                        inputProps={{
                                                            ...params.inputProps,
                                                            autoComplete: 'new-password', // disable autocomplete and autofill
                                                        }}
                                                    />
                                                )}
                                                onChange={(event, newValue) => {
                                                    setSelectedCategory(newValue);
                                                }}
                                            />
                                        </FormControl>
                                        <FormControl sx={{ m: 1, width: '30ch' }} variant="filled">
                                                <InputLabel htmlFor="filled-adornment-amount">Alış Fiyatı</InputLabel>
                                                <FilledInput
                                                    id="filled-adornment-amount"
                                                    startAdornment={<InputAdornment position="start">₺</InputAdornment>}
                                                    value={purchasePrice}
                                                    onChange={(e) => setPurchasePrice(e.target.value)}
                                                />
                                            </FormControl>
                                    </div>





                                    <ToastContainer />
                                </Box>
                                <Button
                                    sx={{ m: 3 }}
                                    color="primary"
                                    variant="contained"
                                    disabled={!selectedCategory}
                                    type='submit'>
                                    Kaydet
                                </Button>
                            </form>


                        </Card>
                    </TabPanel>
                    <TabPanel value="2">
                        <Container>


                            <form onSubmit={submitHandleForStockMovement}>
                                <Card>
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', margin: '20px' }}>
                                        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
                                            <Typography variant="h4" gutterBottom sx={{ display: 'flex', flexWrap: 'wrap', margin: '20px' }}>
                                                Stok Bilgileri
                                            </Typography>

                                        </Stack>

                                        <div style={{ display: 'flex', gap: '16px' }}>

                                            <FormControl sx={{ m: 1, width: '30ch' }} variant="filled">
                                                <InputLabel htmlFor="filled-adornment-amount">Eklenecek Stok (Adet)</InputLabel>
                                                <FilledInput
                                                    id="filled-adornment-amount"

                                                    value={increasingStock}
                                                    onChange={(e) => setIncreasingStock(e.target.value)}
                                                />
                                            </FormControl>
                                            <FormControl sx={{ m: 1, width: '30ch' }} variant="filled">
                                                <InputLabel htmlFor="filled-adornment-amount">Çıkarılacak Stok (Adet)</InputLabel>
                                                <FilledInput
                                                    id="filled-adornment-amount"

                                                    value={decreasingStock}
                                                    onChange={(e) => setDecreasingStock(e.target.value)}
                                                />
                                            </FormControl>
                                            <FormControl sx={{ m: 1, width: '30ch' }} variant="filled">
                                                <InputLabel htmlFor="filled-adornment-amount">Toplam Stok</InputLabel>
                                                <FilledInput
                                                    id="filled-adornment-amount"
                                                    readOnly
                                                    value={totalStock}
                                                    onChange={(e) => setTotalStock(e.target.value)}
                                                />
                                            </FormControl>

                                        </div>
                                    </Box>
                                </Card>
                                <br />
                                <Card>
                                    <Box sx={{ display: 'flexs', flexWrap: 'wrap', margin: '20px' }}>

                                        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
                                            <Typography variant="h4" gutterBottom sx={{ display: 'flex', flexWrap: 'wrap', margin: '20px' }}>
                                                Fiyat Bilgileri
                                            </Typography>

                                        </Stack>

                                        <div style={{ display: 'flex', gap: '16px' }}>

                                            <FormControl>
                                                <Autocomplete
                                                    id="country-select-demo"
                                                    sx={{ m: 1, width: '50ch' }}
                                                    options={pricecategories}
                                                    autoHighlight
                                                    value={selectedPriceCategory}
                                                    getOptionLabel={(option) => option.telephoneMaterialPriceCategoryName}
                                                    isOptionEqualToValue={useCallback((option, value) => option.value === value.value, [])}
                                                    renderOption={(props, option) => (
                                                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>

                                                            {option.telephoneMaterialPriceCategoryName}
                                                        </Box>
                                                    )}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Müşteri Tipi Seçiniz"
                                                            inputProps={{
                                                                ...params.inputProps,
                                                                autoComplete: 'new-password', // disable autocomplete and autofill
                                                            }}
                                                        />
                                                    )}
                                                    onChange={(event, newValue) => {
                                                        setSelectedPriceCategory(newValue);
                                                    }}
                                                />
                                            </FormControl>
                                        </div>

                                        <div style={{ display: 'flex', gap: '16px' }}>

                                            <FormControl sx={{ m: 1, width: '30ch' }} variant="filled">
                                                <InputLabel htmlFor="filled-adornment-amount">Fiyat</InputLabel>
                                                <FilledInput
                                                    id="filled-adornment-amount"
                                                    startAdornment={<InputAdornment position="start">₺</InputAdornment>}
                                                    value={price}
                                                    onChange={(e) => setPrice(e.target.value)}
                                                />
                                            </FormControl>
                                            <FormControl sx={{ m: 1, width: '30ch' }} variant="filled">
                                                <InputLabel htmlFor="filled-adornment-amount">KDV Oranı</InputLabel>
                                                <FilledInput
                                                    id="filled-adornment-amount"
                                                    startAdornment={<InputAdornment position="start">%</InputAdornment>}
                                                    value={kdvRate}
                                                    onChange={(e) => setKdvRate(e.target.value)}
                                                />
                                            </FormControl>
                                            <FormControl sx={{ m: 1, width: '30ch' }} variant="filled">
                                                <InputLabel htmlFor="filled-adornment-amount">İndirim</InputLabel>
                                                <FilledInput
                                                    id="filled-adornment-amount"
                                                    startAdornment={<InputAdornment position="start">₺</InputAdornment>}
                                                    value={discount}
                                                    onChange={(e) => setDiscount(e.target.value)}
                                                />
                                            </FormControl>
                                        </div>
                                        <div style={{ display: 'flex', gap: '16px' }}>

                                            <FormControl sx={{ m: 1, width: '50ch' }} variant="standard">
                                                <InputLabel htmlFor="standard-adornment-amount">Toplam Fiyat</InputLabel>
                                                <Input
                                                    readOnly
                                                    id="standard-adornment-amount"
                                                    startAdornment={<InputAdornment position="start">₺</InputAdornment>}
                                                    value={totalPrice === '' ? '0' : totalPrice}
                                                    onChange={(e) => setTotalPrice(e.target.value)}
                                                />

                                            </FormControl>

                                        </div>
                                    </Box>
                                    <Button
                                        sx={{ m: 3 }}
                                        color="primary"
                                        variant="contained"

                                        type='submit'>
                                        Kaydet
                                    </Button>
                                </Card>

                                <ToastContainer />



                            </form>
                            <br />
                            <Card>
                                <List sx={{ m: 1 }}>
                                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
                                        <Typography variant="h4" gutterBottom sx={{ display: 'flex', flexWrap: 'wrap', margin: '20px' }}>
                                            Özet Bilgi
                                        </Typography>

                                    </Stack>
                                    <ListItem>
                                        <ListItemText primary="Fiyat" secondary={`${price} ₺`} />
                                        <ListItemText primary="KDV Oranı" secondary={`% ${kdvRate}`} />
                                        <ListItemText primary="KDV Den Eklenen Fiyat" secondary={`${(price * (kdvRate / 100)).toFixed(2)} ₺`} />
                                        <ListItemText primary="İndirim" secondary={`${discount} ₺`} />
                                        <ListItemText primary="Toplam Fiyat" secondary={`${totalPrice} ₺`} />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText primary="Stok Miktarı" secondary={`${increasingStock - decreasingStock} Adet`} />
                                    </ListItem>
                                </List>
                            </Card>
                        </Container>
                    </TabPanel>
                </Container>
            </TabContext>
        </>
    );
}