import * as React from 'react';
import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    Card,
    Container,
    Stack,
    Typography,
    Autocomplete,
    Box,
    Input,
    FilledInput,
    InputLabel,
    InputAdornment,
    FormControl,
    TextField,
    Button,
    ListItem,
    ListItemText,
    List,
    IconButton
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { getTelephoneMaterials, getTelephoneMaterialPriceByMaterialId, addTelephone,getTelephoneMasters,getTelephoneCategories,getTelephoneModels } from '../../services';



export default function TelephoneMaterialSaleAdd() {
    const [price, setPrice] = useState('0');
    const [discount, setDiscount] = useState('0');
    const [totalPrice, setTotalPrice] = useState('0');
    const [telephonematerials, setTelephoneMaterials] = useState([]);
    const [selectedProduct] = useState(null);
    const [selectedModel,setSelectedModel] = useState(null);
    const [selectedTelephoneCategory,setSelectedTelephoneCategory] = useState(null);
    const [selectedMaster,setSelectedMaster] = useState(null);
    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    const [rows, setRows] = useState([{ selectedProduct: null, selectedCategory: null }]);
    const [quantities, setQuantities] = useState(rows.map(() => 0));
    const [models,setModels] = useState([])
    const [masters,setMasters] = useState([])
    const [categories,setCategories] = useState([])
    const navigate = useNavigate();

    useEffect(() => {
        const rowTotalPrices = rows.map((row, index) => {
            const productPrice = row.selectedCategory?.telephoneMaterialSubTotal || 0;
            const quantity = quantities[index] || 0;
            return productPrice * quantity;
        });

        const totalRowPrice = rowTotalPrices.reduce((acc, current) => acc + current, 0);
        const addingPrice = price - discount
        const indirimliTotal = addingPrice + totalRowPrice;

        setTotalPrice(indirimliTotal.toFixed(2));// Toplam Fiyatı iki ondalık basamağa yuvarla
        getAllTelephoneMaterials();
    }, [price, discount, selectedProduct, quantities, rows])

    useEffect(() => {
        getAllTelephoneMasters()
        getAllTelephoneModels()
        getAllTelephoneCategories()
    })
    const getAllTelephoneMaterials = () => {
        getTelephoneMaterials()
            .then((data) => setTelephoneMaterials(data))
            .catch((err) => console.log(err));

    };

    const getAllTelephoneMasters = () => {
        getTelephoneMasters()
            .then((data) => setMasters(data))
            .catch((err) => console.log(err));

    };

    const getAllTelephoneModels = () => {
        getTelephoneModels()
            .then((data) => setModels(data))
            .catch((err) => console.log(err));

    };
    const getAllTelephoneCategories = () => {
        getTelephoneCategories()
            .then((data) => setCategories(data))
            .catch((err) => console.log(err));

    };
    const getById = (id, rowIndex) => {
        getTelephoneMaterialPriceByMaterialId(id)
            .then((data) => {
                // Set the fetched data to the state for the specific row
                const newRows = [...rows];
                newRows[rowIndex].telephonematerialprices = data;
                setRows(newRows);
            })
            .catch((err) => console.log(err));
       
    }

    const isOptionEqualToValue = useCallback((option, value) => option.value === value.value, []);

    const handleModelChange = (newValue) => {
        setSelectedModel(newValue);
    };
    const handleCategoryChange = (newValue) => {
        setSelectedTelephoneCategory(newValue);
    };
    
    const handleMasterChange = (newValue) => {
        setSelectedMaster(newValue);
    };
    const submitHandle = (e) => {
        e.preventDefault();
        const data = {
            telephone: {
                title,
                description,           
                price: parseFloat(totalPrice),
                telephoneCategoryId:selectedTelephoneCategory.telephoneCategoryId,
                telephoneModelId:selectedModel.telephoneModelId,
                telephoneMasterId:selectedMaster.telephoneMasterId
                // Add other fields as needed
            },
            telephoneUsedMaterials: rows.map((row, index) => ({
                telephoneMaterialId: row.selectedProduct?.telephoneMaterialId || 0,
                telephoneMaterialPriceId: row.selectedCategory?.telephoneMaterialPriceId || 0,
                quantity: quantities[index] || 0,
            })),
            telephoneMaterialStockMovement: {
                telephoneMaterialStockQuantityIncreasing: 0,
                telephoneMaterialStockQuantityDecreasing: 0,
                telephoneMaterialStockQuantity: 0,

            }
        };

        addTelephone(data)
            .then((res) => {
                if (res.success === true) {
                    toast.success(res.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });

                    // 1,5 saniye sonra yönlendirme yap
                    setTimeout(() => {
                        navigate('/dashboard/telephones');
                    }, 1500); // 1,5 saniye (1500 milisaniye) bekleyecek
                }
            })
            .catch((err) => console.log(err));
    }

    const deleteRows = (index) => {
        const newRows = [...rows];
        newRows.splice(index, 1);
        setRows(newRows);
    };

    const addRow = () => {
        setRows([...rows, { selectedProduct: null, selectedCategory: null }]);
    }

    function formatNumberWithCommas(number) {
        const formatter = new Intl.NumberFormat('tr-TR');
        return formatter.format(number);
    }

    const handleQuantityChange = (e, rowIndex) => {
        const newQuantities = [...quantities];
        newQuantities[rowIndex] = parseInt(e.target.value, 10) || 0;
        setQuantities(newQuantities);
    };

    return (

        <>
            <Helmet>
                <title> Yeni Cihaz Tamiri </title>
            </Helmet>
            <Container>
                <form onSubmit={submitHandle}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                        <Typography variant="h4" gutterBottom>
                            Yeni Cihaz Tamiri
                        </Typography>

                    </Stack>
                    <Card>
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', margin: '20px' }}>

                            <TextField
                                id="outlined-helperText"
                                label="Başlık"
                                sx={{ m: 1, width: '90ch' }}
                                onChange={(e) => setTitle(e.target.value)}
                            />
                            <TextField
                                id="outlined-multiline-static"
                                label="Tamir Detayı"
                                multiline
                                rows={4}
                                onChange={(e) => setDescription(e.target.value)}
                                sx={{ m: 1, width: '90ch' }}
                            />
                        </Box>
                    </Card>
                    <br />
                    <Card>
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', margin: '20px' }}>

                            <Autocomplete
                                id="country-select-demo"
                                sx={{ m: 1, width: '30ch' }}
                                options={models}
                                autoHighlight
                                getOptionLabel={(option) => option.telephoneModelName}
                                isOptionEqualToValue={isOptionEqualToValue}
                                renderOption={(props, option) => (
                                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                        {option.telephoneModelName}
                                    </Box>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Model Seçiniz"
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: 'new-password', // disable autocomplete and autofill
                                        }}
                                    />
                                )}
                                value={selectedModel}
                                onChange={(event, newValue) => handleModelChange(newValue)}
                            />
                            <Autocomplete
                                id="country-select-demo"
                                sx={{ m: 1, width: '30ch' }}
                                options={categories}
                                autoHighlight
                                getOptionLabel={(option) => option.telephoneCategoryName}
                                isOptionEqualToValue={isOptionEqualToValue}
                                renderOption={(props, option) => (
                                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                        {option.telephoneCategoryName}
                                    </Box>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Kategori Seçiniz"
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: 'new-password', // disable autocomplete and autofill
                                        }}
                                    />
                                )}
                                value={selectedTelephoneCategory}
                                onChange={(event, newValue) => handleCategoryChange(newValue)}
                            />
                            <Autocomplete
                                id="country-select-demo"
                                sx={{ m: 1, width: '30ch' }}
                                options={masters}
                                autoHighlight
                                getOptionLabel={(option) => option.telephoneMasterName}
                                isOptionEqualToValue={isOptionEqualToValue}
                                renderOption={(props, option) => (
                                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                        {option.telephoneMasterName}
                                    </Box>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Usta Seçiniz"
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: 'new-password', // disable autocomplete and autofill
                                        }}
                                    />
                                )}
                                value={selectedMaster}
                                onChange={(event, newValue) => handleMasterChange(newValue)}
                            />
                        </Box>
                    </Card>
                    <br />
                    <Card>
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', margin: '20px' }}>
                            {rows.map((row, index) => (
                                <div style={{ display: 'flex', gap: '16px' }} key={index}>
                                    <FormControl>
                                        <Autocomplete
                                            id="country-select-demo"
                                            sx={{ m: 1, width: '40ch' }}
                                            options={telephonematerials}
                                            autoHighlight
                                            getOptionLabel={(option) => option.telephoneMaterialName}
                                            isOptionEqualToValue={isOptionEqualToValue}
                                            renderOption={(props, option) => (
                                                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                    {option.telephoneMaterialName}
                                                </Box>
                                            )}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Ürün Seçiniz"
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        autoComplete: 'new-password', // disable autocomplete and autofill
                                                    }}
                                                />
                                            )}
                                            value={row.selectedProduct}
                                            onChange={(event, newValue) => {
                                                const newRows = [...rows];
                                                newRows[index].selectedProduct = newValue;
                                                setRows(newRows);

                                                if (newValue && newValue.telephoneMaterialId) {
                                                    getById(newValue.telephoneMaterialId, index);
                                                    // index'i de ileterek hangi satırın güncelleneceğini belirleyin
                                                }
                                            }}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <Autocomplete
                                            id="country-select-demo"
                                            sx={{ m: 1, width: '40ch' }}
                                            options={row.telephonematerialprices}
                                            autoHighlight
                                            getOptionLabel={(option) => `${formatNumberWithCommas(option.telephoneMaterialSubTotal.toString())} ₺`}
                                            isOptionEqualToValue={isOptionEqualToValue}
                                            renderOption={(props, option) => (
                                                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                    {formatNumberWithCommas(option.telephoneMaterialSubTotal)}₺  / {option.telephoneMaterialPriceCategoryName}
                                                </Box>
                                            )}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Fiyat Seçiniz"
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        autoComplete: 'new-password', // disable autocomplete and autofill
                                                    }}
                                                />
                                            )}
                                            value={row.selectedCategory}
                                            onChange={(event, newValue) => {
                                                const newRows = [...rows];
                                                newRows[index].selectedCategory = newValue;
                                                setRows(newRows);
                                            }}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <TextField
                                            id="outlined-number"
                                            label="Adet"
                                            type="number"
                                            sx={{ m: 1, width: '10ch' }}
                                            value={quantities[index]}
                                            onChange={(e) => handleQuantityChange(e, index)}
                                        />
                                    </FormControl>
                                    <IconButton
                                        aria-label="edit"
                                        color="error"
                                        onClick={() => deleteRows(index)}
                                    >
                                        <RemoveIcon />
                                    </IconButton>



                                </div>
                            ))}
                            <IconButton
                                aria-label="edit"
                                color="primary"
                                onClick={addRow}
                            >
                                <AddIcon />
                            </IconButton>
                        </Box>
                    </Card>
                    <br />
                    <Card>
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', margin: '20px' }}>

                            <div>
                                <FormControl sx={{ m: 1, width: '30ch' }} variant="filled">
                                    <InputLabel htmlFor="filled-adornment-amount">Ekleneck Fiyat</InputLabel>
                                    <FilledInput
                                        id="filled-adornment-amount"
                                        startAdornment={<InputAdornment position="start">₺</InputAdornment>}
                                        value={price}
                                        onChange={(e) => setPrice(e.target.value)}
                                    />
                                </FormControl>

                                <FormControl sx={{ m: 1, width: '30ch' }} variant="filled">
                                    <InputLabel htmlFor="filled-adornment-amount">İndirim</InputLabel>
                                    <FilledInput
                                        id="filled-adornment-amount"
                                        startAdornment={<InputAdornment position="start">₺</InputAdornment>}
                                        value={discount}
                                        onChange={(e) => setDiscount(e.target.value)}
                                    />
                                </FormControl>

                            </div>
                            <div>
                                <FormControl sx={{ m: 1, width: '50ch' }} variant="standard">
                                    <InputLabel htmlFor="standard-adornment-amount">Toplam Fiyat</InputLabel>
                                    <Input
                                        readOnly
                                        id="standard-adornment-amount"
                                        startAdornment={<InputAdornment position="start">₺</InputAdornment>}
                                        value={totalPrice === '' ? '0' : totalPrice}
                                        onChange={(e) => setTotalPrice(e.target.value)}
                                    />
                                </FormControl>
                            </div>
                        </Box>
                    </Card>                <ToastContainer />
                    <Button
                        sx={{ m: 3 }}
                        color="primary"
                        variant="contained"
                        type='submit'
                    >
                        Kaydet
                    </Button>
                    <hr />
                    <List sx={{ m: 1 }}>
                        <ListItem>
                            <ListItemText primary="Eklenecek Fiyat" secondary={`${price} ₺`} />
                            <ListItemText primary="İndirim" secondary={`${discount} ₺`} />
                            <ListItemText primary="Toplam Fiyat" secondary={`${totalPrice} ₺`} />
                        </ListItem>
                    </List>
                </form>
            </Container >
        </>
    );
}