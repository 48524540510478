export const postMultipart = (url, formData) => 
// eslint-disable-next-line
{
    return new Promise((resolve, reject) => {
      
      const options = {
        method: 'POST',
        body: formData, // FormData nesnesi
        
      };
  
      fetch(process.env.REACT_APP_API_URL + url, options)
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          if (response.status === 400) {
            // Durum kodu 400 ise, hata mesajını almak için response metnini döndürün
            return response.text().then((errorText) => {
              throw new Error(errorText);
            });
          }
          throw new Error('Response not OK');
        })
        .then((result) => {
          resolve(result);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };