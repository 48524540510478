import * as React from 'react';
import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    Card,
    Container,
    Stack,
    Typography,
    Autocomplete,
    Box,
    Input,
    FilledInput,
    InputLabel,
    InputAdornment,
    FormControl,
    TextField,
    Button,
    ListItem,
    ListItemText,
    List,
} from '@mui/material';

import { getTelephoneMaterials, getTelephoneMaterialPriceCategories, addTelephoneMaterialPrice } from '../../../services';



export default function TelephoneMaterialPriceAdd() {
    const [price, setPrice] = useState('0');
    const [kdvRate, setKdvRate] = useState('0');
    const [discount, setDiscount] = useState('0');
    const [totalPrice, setTotalPrice] = useState('0');
    const [telephonematerials, setTelephoneMaterials] = useState([]);
    const [telephonematerialpricecategories, setTelephoneMaterialPriceCategories] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const navigate = useNavigate();


    useEffect(() => {
        // KDV'yi hesapla
        const vergimiktari = price * (kdvRate / 100);
        const vergiliTotal = vergimiktari + parseFloat(price);
        const indirimtotali = parseFloat(discount);

        // Toplam Fiyatı hesapla
        const indirimliTotal = vergiliTotal - indirimtotali;

        setTotalPrice(indirimliTotal.toFixed(2));// Toplam Fiyatı iki ondalık basamağa yuvarla
        getAllTelephoneMaterials();
        getAllTelephoneMaterialPriceCategories();
    }, [price, kdvRate, discount])

    const getAllTelephoneMaterials = () => {
        getTelephoneMaterials()
            .then((data) => setTelephoneMaterials(data))
            .catch((err) => console.log(err));

    };

    const getAllTelephoneMaterialPriceCategories = () => {
        getTelephoneMaterialPriceCategories()
            .then((data) => setTelephoneMaterialPriceCategories(data))
            .catch((err) => console.log(err));

    };


    const submitHandle = (e) => {
        e.preventDefault();
        const data = {
            telephoneMaterialId: selectedProduct.telephoneMaterialId,
            telephoneMaterialPriceCategoryId: selectedCategory.telephoneMaterialPriceCategoryId,
            telephoneMaterialTotalPrice: price,
            telephoneMaterialDiscountRate: discount,
            telephoneMaterialVatRate: kdvRate,
            telephoneMaterialSubTotal: totalPrice,
        };

        addTelephoneMaterialPrice(data)
        .then((res) => {
            if (res.success === true) {
                toast.dismiss();
                toast.success(res.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });

                // 1,5 saniye sonra yönlendirme yap
                setTimeout(() => {
                    navigate('/dashboard/telephonematerialprices');
                }, 1500); // 1,5 saniye (1500 milisaniye) bekleyecek
            }
        })
        .catch((err) => console.log(err));
    }

    return (

        <>
            <Helmet>
                <title> Yeni Malzeme Ücreti </title>
            </Helmet>
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Yeni Malzeme Ücreti
                    </Typography>

                </Stack>
                <Card>
                    <form onSubmit={submitHandle}>
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', margin: '20px' }}>
                            <div style={{ display: 'flex', gap: '16px' }}>
                                <FormControl>
                                    <Autocomplete
                                        id="country-select-demo"
                                        sx={{ m: 1, width: '50ch' }}
                                        options={telephonematerials}
                                        autoHighlight
                                        getOptionLabel={(option) => option.telephoneMaterialName}
                                        isOptionEqualToValue={useCallback((option, value) => option.value === value.value, [])}
                                        renderOption={(props, option) => (
                                            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>

                                                {option.telephoneMaterialName}
                                            </Box>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Ürün Seçiniz"
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: 'new-password', // disable autocomplete and autofill
                                                }}
                                            />
                                        )}
                                        value={selectedProduct}
                                        onChange={(event, newValue) => {
                                            setSelectedProduct(newValue);
                                        }}
                                    />
                                </FormControl>
                                <FormControl>
                                    <Autocomplete
                                        id="country-select-demo"
                                        sx={{ m: 1, width: '50ch' }}
                                        options={telephonematerialpricecategories}
                                        autoHighlight
                                        value={selectedCategory}
                                        getOptionLabel={(option) => option.telephoneMaterialPriceCategoryName}
                                        isOptionEqualToValue={useCallback((option, value) => option.value === value.value, [])}
                                        renderOption={(props, option) => (
                                            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>

                                                {option.telephoneMaterialPriceCategoryName}
                                            </Box>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Müşteri Tipi Seçiniz"
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: 'new-password', // disable autocomplete and autofill
                                                }}
                                            />
                                        )}
                                        onChange={(event, newValue) => {
                                            setSelectedCategory(newValue);
                                        }}
                                    />
                                </FormControl>
                            </div>

                            <div>

                                <FormControl sx={{ m: 1, width: '30ch' }} variant="filled">
                                    <InputLabel htmlFor="filled-adornment-amount">Fiyat</InputLabel>
                                    <FilledInput
                                        id="filled-adornment-amount"
                                        startAdornment={<InputAdornment position="start">₺</InputAdornment>}
                                        value={price}
                                        onChange={(e) => setPrice(e.target.value)}
                                    />
                                </FormControl>
                                <FormControl sx={{ m: 1, width: '30ch' }} variant="filled">
                                    <InputLabel htmlFor="filled-adornment-amount">KDV Oranı</InputLabel>
                                    <FilledInput
                                        id="filled-adornment-amount"
                                        startAdornment={<InputAdornment position="start">%</InputAdornment>}
                                        value={kdvRate}
                                        onChange={(e) => setKdvRate(e.target.value)}
                                    />
                                </FormControl>
                                <FormControl sx={{ m: 1, width: '30ch' }} variant="filled">
                                    <InputLabel htmlFor="filled-adornment-amount">İndirim</InputLabel>
                                    <FilledInput
                                        id="filled-adornment-amount"
                                        startAdornment={<InputAdornment position="start">₺</InputAdornment>}
                                        value={discount}
                                        onChange={(e) => setDiscount(e.target.value)}
                                    />
                                </FormControl>
                            </div>
                            <div>

                                <FormControl sx={{ m: 1, width: '50ch' }} variant="standard">
                                    <InputLabel htmlFor="standard-adornment-amount">Toplam Fiyat</InputLabel>
                                    <Input
                                        readOnly
                                        id="standard-adornment-amount"
                                        startAdornment={<InputAdornment position="start">₺</InputAdornment>}
                                        value={totalPrice === '' ? '0' : totalPrice}
                                        onChange={(e) => setTotalPrice(e.target.value)}
                                    />

                                </FormControl>

                            </div>


                            <ToastContainer />
                        </Box>
                        <Button
                            sx={{ m: 3 }}
                            color="primary"
                            variant="contained"
                            disabled={!selectedCategory || !selectedProduct}
                            type='submit'>
                            Kaydet
                        </Button>
                    </form>
                    <List sx={{ m: 1 }}>
                        <ListItem>
                            <ListItemText primary="Fiyat" secondary={`${price} ₺`} />
                            <ListItemText primary="KDV Oranı" secondary={`% ${kdvRate}`} />
                            <ListItemText primary="KDV Den Eklenen Fiyat" secondary={`${(price * (kdvRate / 100)).toFixed(2)} ₺`} />
                            <ListItemText primary="İndirim" secondary={`% ${discount} ₺`} />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Toplam Fiyat" secondary={`${totalPrice} ₺`} />
                        </ListItem>
                    </List>

                </Card>
            </Container>
        </>
    );
}