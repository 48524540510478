import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  Card,
  Container,
  Stack,
  TextField,
  Button,
  Checkbox,
  FormControlLabel, // Import FormControlLabel for the Checkbox
} from '@mui/material';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { updateTelephoneModel } from '../../../services';

function TelephoneModelUpdateModal({
  onUpdateSuccess,
  isOpen,
  onClose,
  telephoneModelId,
  telephoneModelName,
  isDeleted,
}) {
  const [updatedName, setUpdatedName] = useState(telephoneModelName);
  const [isStatus, setIsStatus] = useState(isDeleted);

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      telephoneModelId,
      telephoneModelName: updatedName,
      isDeleted: isStatus,
    };
    
    updateTelephoneModel(data)
      .then((res) => {
        if (res.success === true) {
          toast.dismiss();
          toast.success(res.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });

          if (onUpdateSuccess) {
            onUpdateSuccess();
          }

          onClose();
          setUpdatedName('');
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (isOpen) {
      setUpdatedName(telephoneModelName);
      setIsStatus(isDeleted);
    }
  }, [isOpen, telephoneModelName, isDeleted]);

  return (
    <Modal open={isOpen} onClose={onClose}>
      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
        }}
      >
        <Card>
          <Container sx={{ p: 4 }}>
            <form onSubmit={handleSubmit}>
              <Stack spacing={3}>
                <TextField
                  type="text"
                  name="telephoneModelName"
                  value={updatedName}
                  label="Kategori İsmi"
                  onChange={(e) => setUpdatedName(e.target.value)}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isStatus}
                      onChange={() => setIsStatus(!isStatus)}
                    />
                  }
                  label="Pasif Yap"
                  
                />
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  disabled={!updatedName}
                >
                  Güncelle
                </Button>
              </Stack>
            </form>
          </Container>
        </Card>
      </div>
    </Modal>
  );
}

TelephoneModelUpdateModal.propTypes = {
  onUpdateSuccess: PropTypes.func,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  telephoneModelId: PropTypes.number.isRequired,
  telephoneModelName: PropTypes.string.isRequired,
  isDeleted: PropTypes.bool.isRequired,
};

export default TelephoneModelUpdateModal;