import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// @mui
import {
    Card,
    Table,
    Paper,
    Stack,
    Checkbox,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    IconButton,
    TableContainer,
    TablePagination,

} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
// components
import Label from '../../../components/label'; // Eğer 'components' klasörü 'telephoneCategory' klasörüne üst düzeyde ise
import Scrollbar from '../../../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../../../sections/@dashboard/user';
import { getTelephoneModels, deleteTelephoneModel } from '../../../services';
import TelephoneModelAddModal from './TelephoneModelAddModal';
import TelephoneModelUpdateModal from './TelephoneModelUpdateModal';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
    { id: 'id', label: 'Id', alignRight: false },
    { id: 'name', label: 'Model İsmi', alignRight: false },
    { id: 'cDate', label: 'Tarih', alignRight: false },  
    { id: 'status', label: 'Durumu', alignRight: false },
    { id: '', label: 'İşlemler', alignRight: false },
];

// ----------------------------------------------------------------------
function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(
            array,
            (telephonemodel) => telephonemodel.telephoneModelName.toLowerCase().indexOf(query.toLowerCase()) !== -1
        );
    }
    return stabilizedThis.map((el) => el[0]);
}

export default function UserPage() {

    const [page, setPage] = useState(0);
    const [order, setOrder] = useState('asc');
    const [selected, setSelected] = useState([]);
    const [orderBy, setOrderBy] = useState('name');
    const [filterName, setFilterName] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [telephonemodels, setTelephoneModels] = useState([]);
    const [isUpdateModalOpen, setUpdateModalOpen] = useState(false);
    const [isDeleted, setIsDeleted] = useState(null);
    const [selectedModelId, setSelectedModelId] = useState(null);
    const [selectedModelName, setSelectedModelName] = useState('');

    const handleEditClick = (modelId, modelName,isDeleted) => {
        setSelectedModelId(modelId);
        setSelectedModelName(modelName);
        setIsDeleted(isDeleted)
        setUpdateModalOpen(true);
    };


    useEffect(() => {
        getAll();
    }, []);

    const getAll = () => {
        getTelephoneModels()
            .then((data) => setTelephoneModels(data))
            .catch((err) => console.log(err));      
    };

    function formatDate(dateString) {
        // Tarih dizesini JavaScript Date nesnesine dönüştür
        const date = new Date(dateString);

        // Tarih nesnesini istediğiniz formata biçimlendirin (örneğin: yyyy-MM-dd)
        const formattedDate = date.toLocaleDateString('tr-TR', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
        });

        return formattedDate;
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = telephonemodels.map((n) => n.telephoneModelName);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const handleFilterByName = (event) => {
        setPage(0);
        const query = event.target.value.toLowerCase(); // Arama sorgusunu küçük harfe çevirin
        setFilterName(query);
    };

    const handleAddSuccess = () => {
        getAll(); // Veri eklendikten sonra tabloyu güncelle
    };

    const deleteCategory = (id) => {
        deleteTelephoneModel(id)
            .then((res) => {
                if (res.success === true) {
                    toast.success(res.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                }
                getAll();
            })
            .catch((err) => {
                toast.error(err.message, {
                    position: toast.POSITION.BOTTOM_RIGHT
                })
            });
    };

    const filteredUsers = applySortFilter(telephonemodels, getComparator(order, orderBy), filterName);
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - telephonemodels.length) : 0;
    const isNotFound = !filteredUsers.length && !!filterName;

    return (
        <>
            <Helmet>
                <title> Telefon Modelleri </title>
            </Helmet>

            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Telefon Modelleri
                    </Typography>
                    <TelephoneModelAddModal onAddSuccess={handleAddSuccess}/>
                    <TelephoneModelUpdateModal
                        onUpdateSuccess={handleAddSuccess}
                        isOpen={isUpdateModalOpen}
                        onClose={() => setUpdateModalOpen(false)}
                        telephoneModelId={selectedModelId}
                        telephoneModelName={selectedModelName}
                        isDeleted={isDeleted}
                    />
                </Stack>

                <Card>
                    <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />
                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 800 }}>
                            <Table>
                                <UserListHead
                                    order={order}
                                    orderBy={orderBy}
                                    headLabel={TABLE_HEAD}
                                    rowCount={telephonemodels.length}
                                    numSelected={selected.length}
                                    onRequestSort={handleRequestSort}
                                    onSelectAllClick={handleSelectAllClick}
                                />

                                <TableBody>
                                    {filteredUsers
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((telephonemodel) => (
                                            <TableRow hover key={telephonemodel.telephoneModelId} tabIndex={-1} role="checkbox">
                                                <TableCell padding="checkbox">
                                                    <Checkbox onChange={(event) => handleClick(event)} />
                                                </TableCell>

                                                <TableCell align="left">{telephonemodel.telephoneModelId}</TableCell>

                                                <TableCell align="left"><Label color="secondary">{telephonemodel.telephoneModelName}</Label></TableCell>

                                                <TableCell align="left"><Label color="default">{formatDate(telephonemodel.createdDate)}</Label></TableCell>
                                                
                                                {/* isDeleted durumuna göre metin veya ikon gösterimi */}
                                                <TableCell align="left">
                                                    {telephonemodel.isDeleted ? <Label color="error">Pasif</Label> : <Label color="primary">Aktif</Label>}
                                                </TableCell>

                                                <TableCell>
                                                    <IconButton aria-label="delete" color="error" onClick={() => deleteCategory(telephonemodel.telephoneModelId)} >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                    <IconButton
                                                        aria-label="edit"
                                                        color="primary"
                                                        onClick={() => handleEditClick(telephonemodel.telephoneModelId, telephonemodel.telephoneModelName,telephonemodel.isDeleted)}
                                                    >

                                                        <EditIcon />
                                                    </IconButton>


                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    {emptyRows > 0 && (
                                        <TableRow style={{ height: 53 * emptyRows }}>
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>
                                {isNotFound && (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                                <Paper
                                                    sx={{
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    <Typography variant="h6" paragraph>
                                                        Sonuç Bulunamadı
                                                    </Typography>

                                                    <Typography variant="body2">
                                                        aramak istediğiniz &nbsp;
                                                        <strong>&quot;{filterName}&quot;</strong>.
                                                        <br /> Aradığınız kelimelere uygun sonuç bulunmamaktadır.
                                                    </Typography>
                                                </Paper>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </Scrollbar>

                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={telephonemodels.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Card>
            </Container>
        </>
    );
}
