import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import {  Stack, IconButton, InputAdornment, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/iconify';
import {login} from '../../../services'

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [token, setToken] = useState('');
  // eslint-disable-next-line
  const [expiration,setExpiration] = useState('');
  // eslint-disable-next-line
  const [userName,setUserName] = useState('')
  // eslint-disable-next-line
  const [userMail,setUserMail] = useState('')

  // const handleClick = () => {
  //   navigate('/dashboard', { replace: true });
  // };
  const submitHandle = (e) => {
    e.preventDefault();
    const data = {
      email,
      password
    }
    login(data)
    .then((res) => {
      setToken(res.token)
      setExpiration(res.expiration)
      setUserMail(res.userMail)
      setUserName(res.userName)
      if(token != null){
        navigate('/dashboard/app', { replace: true });
        localStorage.setItem("jwt", `Bearer ${res.token}`);
        localStorage.setItem("expiration",res.expiration)
        localStorage.setItem("user",res.userName)
        localStorage.setItem("mail",res.userMail)
      }
  })
  .catch((err) => console.log(err));
  }

  return (
    <>
     <form onSubmit={submitHandle}>
      <Stack spacing={3}>            
        <TextField name="email" label="Kullanıcı Adı" value={email}
          onChange={(e) => setEmail(e.target.value)} required/>
        <TextField
          name="password"
          label="Şifre"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />     
      </Stack>  
      <LoadingButton fullWidth size="large" type="submit" variant="contained" sx={{ my: 2 }}>
        Giriş Yap
      </LoadingButton>

      
      </form>
     
    </>
  );
}
