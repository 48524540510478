import * as React from 'react';
import { useState, useEffect } from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { MuiFileInput } from 'mui-file-input'
import { toast, ToastContainer } from 'react-toastify';
import tr from "dayjs/locale/tr";
import 'react-toastify/dist/ReactToastify.css';
import {
    Card,
    Container,
    Stack,
    Typography,
    Autocomplete,
    Box,
    Input,
    FilledInput,
    InputLabel,
    InputAdornment,
    FormControl,
    TextField,
    Button,
    ListItem,
    ListItemText,
    List,

} from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { styled } from '@mui/material/styles';

import { updateOldDevice, getTelephoneModels, getOldDevicById } from '../../../services';

const StyledProductImg = styled('img')({

    width: '10%',
    height: '10%',
});


export default function OldDeviceUpdate() {
    const [buyingprice, setBuyingPrice] = useState('0');
    const [sellingprice, setSellingPrice] = useState('0');
    const [totalPrice, setTotalPrice] = useState('0');
    const [models, setModels] = useState([])
    const [selectedModel, setSelectedModel] = useState(null);

    const navigate = useNavigate();

    const devicememories = ['16 GB', '32 GB', '64 GB', '128 GB', '256 GB'];
    const [deviceMemory, setDeviceMemory] = useState('');
    const [deviceMemoryValue, setDeviceMemoryValue] = useState('');

    const devicecolors = ['Kırmızı', 'Mavi', 'Siyah', 'Gold', 'Beyaz'];
    const [color, setcolor] = useState('');
    const [colorInputValue, setColorInputValue] = useState('');

    const deviceCategories = ['1.El Cihaz', '2.El Cihaz',];
    const [deviceCategory, setDeviceCategory] = useState('');
    const [deviceCategoryInputValue, setDeviceCategoryValue] = useState('');

    const [file, setFile] = useState(null)
    const handleChange = (newFile) => {
        setFile(newFile)
    }

    const [imeiNo, setImeiNo] = useState('')
    const [guranteeTerm, setGuranteeTerm] = useState()
    const [buyingCustomerName, setBuyingCustomerName] = useState('')
    const [buyingCustomerTelephoneNo, setBuyingCustomerTelephoneNo] = useState('');
    const [buyingDate, setBuyingDate] = useState()
    const [sellingCustomerName, setSellingCustomerName] = useState('')
    const [sellingCustomerTelephoneNo, setSellingCustomerTelephoneNo] = useState('');
    const [sellingDate, setSellingDate] = useState()
    const [isSold, setIsSold] = useState(false);
    
    const [oldDeviceData, setOldDeviceDatas] = useState([])
    const { id: oldDeviceId } = useParams();


    useEffect(() => {
        const addingPrice = sellingprice - buyingprice
        setTotalPrice(addingPrice.toFixed(2));// Toplam Fiyatı iki ondalık basamağa yuvarla

    }, [buyingprice, sellingprice])

    useEffect(() => {
        getAllTelephoneModels()
        getById(oldDeviceId);
    }, [oldDeviceId])

    useEffect(() => {
        if (oldDeviceData) {
            setBuyingCustomerName(oldDeviceData.buyingCustomerName)
            setBuyingCustomerTelephoneNo(oldDeviceData.buyingCustomerTelephoneNo)
            setBuyingPrice(oldDeviceData.buyingPrice)
            setSellingPrice(oldDeviceData.salePrice)
            setImeiNo(oldDeviceData.imeiNo)
            setcolor(oldDeviceData.color)
            setDeviceMemory(oldDeviceData.deviceMemory)
            setGuranteeTerm(dayjs(oldDeviceData.guranteeTerm))
            setBuyingDate(dayjs(oldDeviceData.buyingDate))
            setIsSold(oldDeviceData.isSold)
            setSellingCustomerName(oldDeviceData.sellingCustomerName)
            setSellingCustomerTelephoneNo(oldDeviceData.sellingCustomerTelephoneNo)
            setDeviceCategory(oldDeviceData.deviceCategory)
            if (oldDeviceData.sellingDate != null) {
                setSellingDate(dayjs(oldDeviceData.sellingDate))
            }
            setFile(oldDeviceData.imagePath)
            const selected = models.find(
                (product) => product.telephoneModelId === oldDeviceData.telephoneModelId
            );
            setSelectedModel(selected)
        }
    }, [oldDeviceData, models])
    const getById = (id) => {
        getOldDevicById(id)
            .then((data) => {
                // Set the fetched data to the state
                setOldDeviceDatas(data);

            })
            .catch((err) => console.log(err));

    }
    const getAllTelephoneModels = () => {
        getTelephoneModels()
            .then((data) => setModels(data))
            .catch((err) => console.log(err));
    };


    const handleIsSoldChange = (event) => {
        setIsSold(event.target.checked);
    };
    const handleModelChange = (newValue) => {
        setSelectedModel(newValue);
    };
    const handleGuranteeTermChange = (date) => {
        setGuranteeTerm(date);
    };
    const handleBuyingDateChange = (date) => {
        setBuyingDate(date);
    };
    const handleSellingDateChange = (date) => {
        setSellingDate(date);
    };
    const submitHandle = (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append("oldDeviceId", oldDeviceId)
        formData.append("telephoneModelId", selectedModel.telephoneModelId);
        formData.append("deviceMemory", deviceMemory);
        formData.append("color", color);

        if (guranteeTerm != null) {
            formData.append("guaranteeTerm", guranteeTerm);
        }

        formData.append("buyingCustomerName", buyingCustomerName);
        formData.append("buyingCustomerTelephoneNo", buyingCustomerTelephoneNo);
        formData.append("imeiNo", imeiNo);
        formData.append("buyingDate", buyingDate);
        formData.append("buyingPrice", buyingprice);

        if (sellingCustomerName != null) {
            formData.append("sellingCustomerName", sellingCustomerName);
        }

        if (sellingCustomerTelephoneNo != null) {
            formData.append("sellingCustomerTelephoneNo", sellingCustomerTelephoneNo);
        }

        if (sellingDate != null) {
            formData.append("sellingDate", sellingDate)
        }

        formData.append("salePrice", sellingprice);
        formData.append("isSold", isSold)
        formData.append("actionId", oldDeviceData.actionId)
        formData.append("imagePath", oldDeviceData.imagePath)
        formData.append("deviceCategory",deviceCategory)
        formData.append("image", file); // Resim dosyasını da ekleyin

        updateOldDevice(formData)
            .then((res) => {
                if (res.success === true) {
                    toast.success(res.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });

                    // 1,5 saniye sonra yönlendirme yap
                    setTimeout(() => {
                        navigate('/dashboard/olddevices');
                    }, 1500); // 1,5 saniye (1500 milisaniye) bekleyecek
                }
            })
            .catch((err) => console.log(err));
    }


    return (

        <>
            <Helmet>
                <title>  Cihaz Güncelleme  </title>
            </Helmet>
            <Container>
                <form onSubmit={submitHandle}  >
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                        <Typography variant="h4" gutterBottom>
                            Cihaz Güncelleme
                        </Typography>
                        <StyledProductImg alt={oldDeviceData.telephoneModelName} src={`https://localhost:44307${oldDeviceData.imagePath}`} />
                    </Stack>
                    <Card>
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', margin: '20px' }}>

                            <Autocomplete
                                id="country-select-demo"
                                sx={{ m: 1, width: '30ch' }}
                                options={models}
                                autoHighlight
                                getOptionLabel={(option) => option.telephoneModelName}

                                renderOption={(props, option) => (
                                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                        {option.telephoneModelName}
                                    </Box>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Model Seçiniz"
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: 'new-password', // disable autocomplete and autofill
                                        }}
                                    />
                                )}
                                value={selectedModel}
                                onChange={(event, newValue) => handleModelChange(newValue)}
                            />
                            <Autocomplete
                                value={deviceMemory}

                                onChange={(event, newValue) => {
                                    setDeviceMemory(newValue);
                                }}
                                inputValue={deviceMemoryValue}
                                onInputChange={(event, newInputValue) => {
                                    setDeviceMemoryValue(newInputValue);
                                }}
                                id="controllable-states-demo"
                                options={devicememories}
                                sx={{ m: 1, width: '30ch' }}
                                renderInput={(params) => <TextField {...params} label="GB" />}
                            />
                            <Autocomplete
                                value={color}

                                onChange={(event, newValue) => {
                                    setcolor(newValue);
                                }}
                                inputValue={colorInputValue}
                                onInputChange={(event, newInputValue) => {
                                    setColorInputValue(newInputValue);
                                }}
                                id="controllable-states-demo"
                                options={devicecolors}
                                sx={{ m: 1, width: '30ch' }}
                                renderInput={(params) => <TextField {...params} label="Renk" />}
                            />

                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={tr}>
                                <DemoContainer components={['DatePicker']}>
                                    <DatePicker label="Garanti Tarihi" sx={{ m: 1, width: '30ch' }} inputFormat="dd-MM-yyyyy" value={guranteeTerm} onChange={handleGuranteeTermChange} />
                                </DemoContainer>
                            </LocalizationProvider>
                            <FormControl sx={{ m: 1, marginTop: '15px', width: '30ch' }}>
                                <TextField id="outlined-basic" label="Alınan Kişi" variant="outlined" value={buyingCustomerName} onChange={(e) => setBuyingCustomerName(e.target.value)} />
                            </FormControl>
                            <FormControl sx={{ m: 1, marginTop: '15px', width: '30ch' }} >
                                <TextField id="outlined-basic" label="Alınan Kişi Numarası" variant="outlined" value={buyingCustomerTelephoneNo} onChange={(e) => setBuyingCustomerTelephoneNo(e.target.value)} inputProps={{
                                    maxLength: 11
                                }}/>
                            </FormControl>

                            <FormControl sx={{ m: 1, width: '30ch' }} >
                                <TextField id="outlined-basic" label="IMEI No" variant="outlined" value={imeiNo} onChange={(e) => setImeiNo(e.target.value)} />
                            </FormControl>
                            <MuiFileInput sx={{ m: 1, width: '30ch' }} value={file} onChange={handleChange} label="Resim Seçiniz" />
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={tr}>
                                <DemoContainer components={['DatePicker']}>
                                    <DatePicker label="Alış Tarihi" sx={{ marginLeft: '10px', width: '30ch' }} inputFormat="dd-MM-yyyyy" value={buyingDate} onChange={handleBuyingDateChange} />
                                </DemoContainer>
                            </LocalizationProvider>

                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={tr}>
                                <DemoContainer components={['DatePicker']}>
                                    <DatePicker label="Satış Tarihi" sx={{ m: 1, width: '30ch' }} inputFormat="dd-MM-yyyyy" value={sellingDate} onChange={handleSellingDateChange} />
                                </DemoContainer>
                            </LocalizationProvider>
                            <FormControl sx={{ m: 1, marginTop: '15px', width: '30ch' }}>
                                <TextField id="outlined-basic" label="Satılan Kişi" variant="outlined" value={sellingCustomerName} onChange={(e) => setSellingCustomerName(e.target.value)} />
                            </FormControl>
                            <FormControl sx={{ m: 1, marginTop: '15px', width: '30ch' }} >
                                <TextField id="outlined-basic" label="Satılan Kişi Numarası" variant="outlined" value={sellingCustomerTelephoneNo} onChange={(e) => setSellingCustomerTelephoneNo(e.target.value)} inputProps={{
                                    maxLength: 11
                                }}/>
                            </FormControl>
                            <Autocomplete
                                value={deviceCategory}
                               
                                onChange={(event, newValue) => {
                                    setDeviceCategory(newValue);
                                }}
                                inputValue={deviceCategoryInputValue}
                                onInputChange={(event, newInputValue) => {
                                    setDeviceCategoryValue(newInputValue);
                                }}
                                id="controllable-states-demo"
                                options={deviceCategories}
                                sx={{ m: 1, width: '30ch' }}
                                renderInput={(params) => <TextField {...params} label="Cihaz Türü" />}
                            />
                            <FormControl sx={{ m: 1, marginTop: '15px', width: '30ch' }} >
                                <FormControlLabel control={<Checkbox checked={isSold} onChange={handleIsSoldChange} />} label="Satış Yapıldı Mı ?" /></FormControl>
                        </Box>
                    </Card>
                    <br />
                    <Card>
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', margin: '20px' }}>

                            <div>
                                <FormControl sx={{ m: 1, width: '30ch' }} variant="filled">
                                    <InputLabel htmlFor="filled-adornment-amount">Alış Fiyatı</InputLabel>
                                    <FilledInput
                                        id="filled-adornment-amount"
                                        startAdornment={<InputAdornment position="start">₺</InputAdornment>}
                                        value={buyingprice}
                                        onChange={(e) => setBuyingPrice(e.target.value)}
                                    />
                                </FormControl>

                                <FormControl sx={{ m: 1, width: '30ch' }} variant="filled">
                                    <InputLabel htmlFor="filled-adornment-amount">Satış Fiyatı</InputLabel>
                                    <FilledInput
                                        id="filled-adornment-amount"
                                        startAdornment={<InputAdornment position="start">₺</InputAdornment>}
                                        value={sellingprice}
                                        onChange={(e) => setSellingPrice(e.target.value)}
                                    />
                                </FormControl>

                            </div>
                            <div>
                                <FormControl sx={{ m: 1, width: '50ch' }} variant="standard">
                                    <InputLabel htmlFor="standard-adornment-amount">Kâr</InputLabel>
                                    <Input
                                        readOnly
                                        id="standard-adornment-amount"
                                        startAdornment={<InputAdornment position="start">₺</InputAdornment>}
                                        value={totalPrice === '' ? '0' : totalPrice}
                                        onChange={(e) => setTotalPrice(e.target.value)}
                                    />
                                </FormControl>
                            </div>
                        </Box>
                    </Card>                <ToastContainer />
                    <Button
                        sx={{ m: 3 }}
                        color="primary"
                        variant="contained"
                        type='submit'
                    >
                        Güncelle
                    </Button>
                    <hr />
                    <List sx={{ m: 1 }}>
                        <ListItem>
                            <ListItemText primary="Alış Fiyatı" secondary={`${buyingprice} ₺`} />
                            <ListItemText primary="Satış Fiyatı" secondary={`${sellingprice} ₺`} />
                            <ListItemText primary="Kâr" secondary={`${totalPrice} ₺`} />
                        </ListItem>
                    </List>
                </form>
            </Container >
        </>
    );
}