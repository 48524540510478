import * as React from 'react';
import { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    Card,
    Container,
    Stack,
    Typography,
    Autocomplete,
    Box,
    Input,
    FilledInput,
    InputLabel,
    InputAdornment,
    FormControl,
    TextField,
    Button,
    ListItem,
    ListItemText,
    List,
    IconButton
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { getTelephoneMaterials, getTelephoneMaterialPriceByMaterialId, updateTelephoneMaterialSale, getTelephoneMaterialSaleById } from '../../../services';



export default function TelephoneMaterialSaleUpdate() {
    const [price, setPrice] = useState('0');
    const [discount, setDiscount] = useState('0');
    const [totalPrice, setTotalPrice] = useState('0');
    const [telephonematerials, setTelephoneMaterials] = useState([]);
    const [selectedProduct] = useState(null);
    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    const [rows, setRows] = useState([{ selectedProduct: null, selectedCategory: null }]);
    const [quantities, setQuantities] = useState(rows.map(() => 0));
    const navigate = useNavigate();
    const [telephoneMaterialSaleData, setTelephoneMaterialSaleData] = useState([null]); // Telefon malzeme satışı verileri için state
    const { id: telephoneMaterialSaleId } = useParams();
    const [initialDataLoaded, setInitialDataLoaded] = useState(false);

    useEffect(() => {
        const rowTotalPrices = rows.map((row, index) => {
            const productPrice = row.selectedCategory?.telephoneMaterialSubTotal || 0;
            const quantity = quantities[index] || 0;
            return productPrice * quantity;
        });

        const totalRowPrice = rowTotalPrices.reduce((acc, current) => acc + current, 0);
        const addingPrice = price - discount
        const indirimliTotal = addingPrice + totalRowPrice;

        setTotalPrice(indirimliTotal.toFixed(2));// Toplam Fiyatı iki ondalık basamağa yuvarla
        if (!initialDataLoaded) {
            // Verilerinizi yükleme işlemleri burada yapılır
            getAllTelephoneMaterials();
            getBySaleId(telephoneMaterialSaleId);
            setInitialDataLoaded(true); // Veriler yüklendikten sonra tekrar çalışmasın
        }


    }, [price, discount, selectedProduct, quantities, rows, telephoneMaterialSaleId, initialDataLoaded])

    
    const getById = (id, rowIndex) => {
        getTelephoneMaterialPriceByMaterialId(id)
            .then((data) => {
                // Set the fetched data to the state for the specific row
                const newRows = [...rows];
                newRows[rowIndex].telephonematerialprices = data;
                setRows(newRows);
            })
            .catch((err) => console.log(err));
    }


    useEffect(() => {
        if (
            telephoneMaterialSaleData &&
            telephoneMaterialSaleData.telephoneMaterialSale
        ) {
            setTitle(telephoneMaterialSaleData.telephoneMaterialSale.title);
            setDescription(telephoneMaterialSaleData.telephoneMaterialSale.description);
            setDiscount(telephoneMaterialSaleData.telephoneMaterialSale.salesDiscount);
            setTotalPrice(telephoneMaterialSaleData.telephoneMaterialSale.salesPrice);

            // Create an array of promises for fetching data
            const promises = telephoneMaterialSaleData.telephoneSoldMaterials.map((material) =>
                getTelephoneMaterialPriceByMaterialId(material.telephoneMaterialId)
            );

            // Use Promise.all to wait for all the data to be fetched
            Promise.all(promises)
                .then((materialPrices) => {
                    // Dönen malzeme fiyatlarını dönün ve her bir malzeme için quantity değerini alın
                    const newRows = materialPrices.map((data, index) => {
                        const selectedProduct = telephonematerials.find(
                            (item) => item.telephoneMaterialId === telephoneMaterialSaleData.telephoneSoldMaterials[index].telephoneMaterialId
                        ) || null;
                        const selectedCategory = data.find(item => item.telephoneMaterialPriceId === telephoneMaterialSaleData.telephoneSoldMaterials[index].telephoneMaterialPriceId);

                        return {
                            selectedProduct,
                            selectedCategory,
                            telephonematerialprices: data,
                            quantity: telephoneMaterialSaleData.telephoneSoldMaterials[index].quantity, // Add quantity from JSON data

                        };

                    });

                    // Set the rows with the fetched data
                    setRows(newRows);
                    // Quantities'i de ayarlayın
                    const newQuantities = telephoneMaterialSaleData.telephoneSoldMaterials.map((material) => material.quantity);
                    setQuantities(newQuantities);
                })
                .catch((err) => console.log(err));
        }
    }, [telephoneMaterialSaleData, telephonematerials]);

    const getAllTelephoneMaterials = () => {
        getTelephoneMaterials()
            .then((data) => {
                setTelephoneMaterials(data);
                // Telefon malzemeleri yüklendikten sonra quantities'i ayarla

            })
            .catch((err) => console.log(err));

    };



    const getBySaleId = (id) => {
        getTelephoneMaterialSaleById(id)
            .then((data) => {
                // Verileri state'e kaydedin
                setTelephoneMaterialSaleData(data);
            })
            .catch((err) => console.log(err));
    }

    const isOptionEqualToValue = useCallback((option, value) => option.value === value.value, []);

    const submitHandle = async (e) => {
        e.preventDefault();

        try {
            const telephoneSoldMaterialsData = rows.map((row, index) => {
                let soldMaterialId = 0; // Varsayılan olarak 0 ayarla

                if (index < telephoneMaterialSaleData.telephoneSoldMaterials.length) {
                    // Eğer index, dizinin uzunluğundan küçükse, soldMaterialId değerini ayarla
                    soldMaterialId = row.selectedProduct && row.selectedProduct.telephoneMaterialId !== 0
                        ? telephoneMaterialSaleData.telephoneSoldMaterials[index].soldMaterialId
                        : 0;
                }

                return {
                    soldMaterialId,
                    telephoneMaterialId: row.selectedProduct?.telephoneMaterialId || 0,
                    telephoneMaterialPriceId: row.selectedCategory?.telephoneMaterialPriceId || 0,
                    quantity: quantities[index] || 0,
                };
            });

            const telephoneMaterialStockMovementsData = telephoneMaterialSaleData.telephoneMaterialStockMovements.map((movement) => ({
                telephoneMaterialStockMovementId: movement.telephoneMaterialStockMovementId,
                telephoneMaterialStockQuantityIncreasing: 0,
                telephoneMaterialStockQuantityDecreasing: 0,
                telephoneMaterialStockQuantity: 0,
            }));

            const data = {
                telephoneMaterialSale: {
                    telephoneMaterialSaleId,
                    title,
                    description,
                    salesDiscount: parseFloat(discount),
                    salesPrice: parseFloat(totalPrice),
                    actionId:telephoneMaterialSaleData.telephoneMaterialSale.actionId
                },
                telephoneSoldMaterials: telephoneSoldMaterialsData,
                telephoneMaterialStockMovements: telephoneMaterialStockMovementsData,
            };

            const res = await updateTelephoneMaterialSale(data);

            if (res.success === true) {
                toast.success(res.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });

                // 1,5 saniye sonra yönlendirme yap
                setTimeout(() => {
                    navigate('/dashboard/telephonematerialsales');
                }, 1500); // 1,5 saniye (1500 milisaniye) bekleyecek
            }
        } catch (err) {
            console.log(err);
        }
    }

    const deleteRows = (index) => {
        const newRows = [...rows];
        newRows.splice(index, 1);
        setRows(newRows);
    };

    const addRow = () => {
        setRows([...rows, { selectedProduct: null, selectedCategory: null }]);
    }

    function formatNumberWithCommas(number) {
        const formatter = new Intl.NumberFormat('tr-TR');
        return formatter.format(number);
    }

    const handleQuantityChange = (e, rowIndex) => {
        const newQuantities = [...quantities];
        newQuantities[rowIndex] = parseInt(e.target.value, 10) || 0;
        setQuantities(newQuantities);
    };

    return (

        <>
            <Helmet>
                <title> Yeni Malzeme Satışı </title>
            </Helmet>
            <Container>
                <form onSubmit={submitHandle}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                        <Typography variant="h4" gutterBottom>
                            Yeni Malzeme Satışı
                        </Typography>

                    </Stack>
                    <Card>
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', margin: '20px' }}>

                            <TextField
                                id="outlined-helperText"
                                label=""
                                sx={{ m: 1, width: '90ch' }}
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                            />
                            <TextField
                                id="outlined-multiline-static"
                                label=""
                                multiline
                                rows={4}
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                sx={{ m: 1, width: '90ch' }}
                            />
                        </Box>
                    </Card>
                    <br />
                    <Card>
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', margin: '20px' }}>
                            {rows.map((row, index) => (
                                <div style={{ display: 'flex', gap: '16px' }} key={index}>
                                    <FormControl>
                                        <Autocomplete
                                            id="country-select-demo"
                                            sx={{ m: 1, width: '40ch' }}
                                            options={telephonematerials}
                                            autoHighlight
                                            getOptionLabel={(option) => option.telephoneMaterialName}
                                            isOptionEqualToValue={isOptionEqualToValue}
                                            renderOption={(props, option) => (
                                                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                    {option.telephoneMaterialName}
                                                </Box>
                                            )}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Ürün Seçiniz"
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        autoComplete: 'new-password', // disable autocomplete and autofill
                                                    }}
                                                />
                                            )}
                                            value={row.selectedProduct}
                                            onChange={(event, newValue) => {
                                                const newRows = [...rows];
                                                newRows[index].selectedProduct = newValue;
                                                setRows(newRows);

                                                if (newValue && newValue.telephoneMaterialId) {
                                                    getById(newValue.telephoneMaterialId, index);

                                                }
                                            }}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <Autocomplete
                                            id="country-select-demo"
                                            sx={{ m: 1, width: '40ch' }}
                                            options={row.telephonematerialprices}
                                            autoHighlight
                                            getOptionLabel={(option) => `${formatNumberWithCommas(option.telephoneMaterialSubTotal)} ₺`}
                                            isOptionEqualToValue={isOptionEqualToValue}
                                            renderOption={(props, option) => (
                                                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                    {formatNumberWithCommas(option.telephoneMaterialSubTotal)}₺  / {option.telephoneMaterialPriceCategoryName}
                                                </Box>
                                            )}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Fiyat Seçiniz"
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        autoComplete: 'new-password', // disable autocomplete and autofill
                                                    }}
                                                />
                                            )}
                                            value={row.selectedCategory}
                                            onChange={(event, newValue) => {
                                                const newRows = [...rows];
                                                newRows[index].selectedCategory = newValue;
                                                setRows(newRows);
                                            }}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <TextField
                                            id="outlined-number"
                                            label="Adet"
                                            type="number"
                                            sx={{ m: 1, width: '10ch' }}
                                            value={quantities[index]}
                                            onChange={(e) => handleQuantityChange(e, index)}
                                        />
                                    </FormControl>
                                    <IconButton
                                        aria-label="edit"
                                        color="error"
                                        onClick={() => deleteRows(index)}
                                    >
                                        <RemoveIcon />
                                    </IconButton>



                                </div>
                            ))}
                            <IconButton
                                aria-label="edit"
                                color="primary"
                                onClick={addRow}
                            >
                                <AddIcon />
                            </IconButton>
                        </Box>
                    </Card>
                    <br />
                    <Card>
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', margin: '20px' }}>

                            <div>
                                <FormControl sx={{ m: 1, width: '30ch' }} variant="filled">
                                    <InputLabel htmlFor="filled-adornment-amount">Ekleneck Fiyat</InputLabel>
                                    <FilledInput
                                        id="filled-adornment-amount"
                                        startAdornment={<InputAdornment position="start">₺</InputAdornment>}
                                        value={price}
                                        onChange={(e) => setPrice(e.target.value)}
                                    />
                                </FormControl>

                                <FormControl sx={{ m: 1, width: '30ch' }} variant="filled">
                                    <InputLabel htmlFor="filled-adornment-amount">İndirim</InputLabel>
                                    <FilledInput
                                        id="filled-adornment-amount"
                                        startAdornment={<InputAdornment position="start">₺</InputAdornment>}
                                        value={discount}
                                        onChange={(e) => setDiscount(e.target.value)}
                                    />
                                </FormControl>

                            </div>
                            <div>
                                <FormControl sx={{ m: 1, width: '50ch' }} variant="standard">
                                    <InputLabel htmlFor="standard-adornment-amount">Toplam Fiyat</InputLabel>
                                    <Input
                                        readOnly
                                        id="standard-adornment-amount"
                                        startAdornment={<InputAdornment position="start">₺</InputAdornment>}
                                        value={totalPrice === '' ? '0' : totalPrice}
                                        onChange={(e) => setTotalPrice(e.target.value)}
                                    />
                                </FormControl>
                            </div>
                        </Box>
                    </Card>                <ToastContainer />
                    <Button
                        sx={{ m: 3 }}
                        color="primary"
                        variant="contained"
                        type='submit'
                    >
                        Güncelle
                    </Button>
                    <hr />
                    <List sx={{ m: 1 }}>
                        <ListItem>
                            <ListItemText primary="Eklenecek Fiyat" secondary={`${price} ₺`} />
                            <ListItemText primary="İndirim" secondary={`${discount} ₺`} />
                            <ListItemText primary="Toplam Fiyat" secondary={`${totalPrice} ₺`} />
                        </ListItem>
                    </List>
                </form>
            </Container >
        </>
    );
}