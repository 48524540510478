import {post,get} from './request'

export const getTelephoneMaterialSales = () => get('/TelephoneMaterialSales/getall')
export const getTelephoneMaterialSalesEarning = () => get('/TelephoneMaterialSales/totalearning')
export const getTelephoneMaterialSalesExpenses = () => get('/TelephoneMaterialSales/totalexpense')
export const getTelephoneMaterialSalesYearlyCount = () => get('/TelephoneMaterialSales/yearlysales')
export const getTelephoneMaterialSaleDetails = () => get('/TelephoneMaterialSales/getallwithdetail')
export const getTelephoneMaterialSaleDetailsDaily = () => get('/TelephoneMaterialSales/getallwithdetailtoday')
export const getTelephoneMaterialSaleCount = () => get('/TelephoneMaterialSales/totalsale')
export const getTelephoneMaterialSaleById = id => get(`/TelephoneMaterialSales/getbyid?telephoneMaterialSaleId=${id}`)
export const deleteTelephoneMaterialSale = id => post(`/TelephoneMaterialSales/delete/${id}`)
export const addTelephoneMaterialSale = data => post('/TelephoneMaterialSales/add',data)
export const updateTelephoneMaterialSale = data => post('/TelephoneMaterialSales/update',data)