import { Helmet } from 'react-helmet-async';
import { useState, useEffect } from 'react';
// @mui
import { Container, Stack, Typography, Button } from '@mui/material';
// components
import { Link } from 'react-router-dom';
import Iconify from '../../../components/iconify';
import { ProductList, ProductFilterSidebar } from '../../../sections/@dashboard/products';
// mock

import { getOlDevicesView } from '../../../services'
// ----------------------------------------------------------------------

export default function ProductsPage() {
    const [openFilter, setOpenFilter] = useState(false);
    const [olddevices, setOldDevices] = useState([]);
    const [isDeleted, setIsDeleted] = useState(false);

    const handleDelete = () => {
        // Silme işlemi burada gerçekleştirildikten sonra
        // setIsDeleted(true) ile durumu güncelleyin.
        setIsDeleted(true);
    };
    useEffect(() => {
        getAll();
        if (isDeleted) {
            getAll(); // isDeleted true olduğunda getAll fonksiyonunu çağırın
        }
    }, [isDeleted]);
    const getAll = () => {
        getOlDevicesView()
            .then((data) => setOldDevices(data))
            .catch((err) => console.log(err));
    };

    const handleOpenFilter = () => {
        setOpenFilter(true);
    };

    const handleCloseFilter = () => {
        setOpenFilter(false);
    };

    return (
        <>
            <Helmet>
                <title> Cihazlar </title>
            </Helmet>

            <Container>
                <Typography variant="h4" sx={{ mb: 5 }}>
                    Cihazlar
                </Typography>
                <Link to="/dashboard/olddeviceadd">
                    <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
                        Yeni Ekle
                    </Button>
                </Link>
                <Stack direction="row" flexWrap="wrap-reverse" alignItems="center" justifyContent="flex-end" sx={{ mb: 5 }}>
                    <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }}>
                        <ProductFilterSidebar
                            openFilter={openFilter}
                            onOpenFilter={handleOpenFilter}
                            onCloseFilter={handleCloseFilter}
                        />

                    </Stack>
                </Stack>

                <ProductList products={olddevices} onDelete={handleDelete} />
               
            </Container>
        </>
    );
}
