import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//


import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';

import DashboardAppPage from './pages/DashboardAppPage';
import TelephoneCategoryList from './pages/telephone/telephoneCategories/TelephoneCategoryList'
import TelephoneModelList from './pages/telephone/telephoneModels/TelephoneModelList'
import TelephoneMasterList from './pages/telephone/telephoneMasters/TelephoneMasterList'
import TelephoneMaterialCategoryList from './pages/telephone/telephoneMaterialCategories/TelephoneMaterialCategoryList'
import TelephoneMaterialPriceCategoryList from './pages/telephone/telephoneMaterialPriceCategories/TelephoneMaterialPriceCategoryList'
import TelephoneMaterialPriceList from './pages/telephone/telephoneMaterialPrice/TelephoneMaterialPriceList'
import TelephoneMaterialPriceAdd from './pages/telephone/telephoneMaterialPrice/TelephoneMaterialPriceAdd';
import TelephoneMaterialPriceUpdate from './pages/telephone/telephoneMaterialPrice/TelephoneMaterialPriceUpdate'
import TelephoneMaterialStockList from './pages/telephone/telephoneMaterialStocks/TelephoneMaterialStockList'
import TelephoneMaterialStockAdd from './pages/telephone/telephoneMaterialStocks/TelephoneMaterialStockAdd'
import TelephoneMaterialStockUpdate from './pages/telephone/telephoneMaterialStocks/TelephoneMaterialStockUpdate'
import TelephoneMaterialSaleList from './pages/telephone/telephoneMaterialSales/TelephoneMaterialSaleList'
import TelephoneMaterialSaleAdd from './pages/telephone/telephoneMaterialSales/TelephoneMaterialSaleAdd'
import TelephoneMaterialSaleUpdate from './pages/telephone/telephoneMaterialSales/TelephoneMaterialSaleUpdate';
import TelephoneMaterialList from './pages/telephone/telephoneMaterials/TelephoneMaterialList'
import TelephoneMaterialAdd from './pages/telephone/telephoneMaterials/TelephoneMaterialAdd'
import TelephoneList from './pages/telephone/TelephoneList'
import TelephoneAdd from './pages/telephone/TelephoneAdd'
import TelephoneUpdate from './pages/telephone/TelephoneUpdate'
import Notification from './pages/telephone/notifications/NotificationList';
import DailyReport from './pages/telephone/reports/DailyReports'
import OldDeviceList from './pages/telephone/olddevices/OldDevicesList'
import OldDeviceAdd from './pages/telephone/olddevices/OldDeviceAdd';
import OldDeviceUpdate from './pages/telephone/olddevices/OldDeviceUpdate';
import SoldOldDevicesList from './pages/telephone/olddevices/SoldOldDevicesList';
import DeviceRepairAdd from './pages/telephone/deviceRepairs/DeviceRepairAdd'
import DeviceRepairList from './pages/telephone/deviceRepairs/DeviceRepairList'
import DeviceRepairUpdate from './pages/telephone/deviceRepairs/DeviceRepairUpdate'
import LoginGuard from './services/LoginGuard';

// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        {
          element: <LoginGuard />, // Use LoginGuard to protect dashboard routes
          children: [
            {
              path: 'app',
              element: <DashboardAppPage />,
              // Add more protected routes here
            },
            { path: 'telephonecategories', element: <TelephoneCategoryList /> },
            { path: 'telephonemodels', element: <TelephoneModelList /> },
            { path: 'telephonemasters', element: <TelephoneMasterList /> },
            { path: 'telephonematerialcategories', element: <TelephoneMaterialCategoryList /> },
            { path: 'telephonematerialpricecategories', element: <TelephoneMaterialPriceCategoryList /> },
            { path: 'telephonematerialprices', element: <TelephoneMaterialPriceList /> },
            { path: 'telephonematerialpriceadd', element: <TelephoneMaterialPriceAdd /> },
            { path: 'telephonematerialprices/edit/:id', element: <TelephoneMaterialPriceUpdate /> },
            { path: 'telephonematerialstocks', element: <TelephoneMaterialStockList /> },
            { path: 'telephonematerialstockadd', element: <TelephoneMaterialStockAdd /> },
            { path: 'telephonematerialstocks/details/:id', element: <TelephoneMaterialStockUpdate /> },
            { path: 'telephonematerialsales', element: <TelephoneMaterialSaleList /> },
            { path: 'telephonematerialsaleadd', element: <TelephoneMaterialSaleAdd /> },
            { path: 'telephonematerialsales/edit/:id', element: <TelephoneMaterialSaleUpdate /> },
            { path: 'telephonematerials', element: <TelephoneMaterialList /> },
            { path: 'telephonematerialadd', element: <TelephoneMaterialAdd /> },
            { path: 'telephones', element: <TelephoneList /> },
            { path: 'telephoneadd', element: <TelephoneAdd /> },
            { path: 'telephones/edit/:id', element: <TelephoneUpdate /> },
            { path: 'notifications', element: <Notification /> },
            { path: 'dailyreports', element: <DailyReport /> },
            { path: 'olddevices', element: <OldDeviceList /> },
            { path: 'olddeviceadd', element: <OldDeviceAdd /> },
            { path: 'olddeviceupdate/edit/:id', element: <OldDeviceUpdate /> },
            { path: 'soldolddevices', element: <SoldOldDevicesList /> },
            { path: 'devicerepairadd', element: <DeviceRepairAdd /> },
            { path: 'devicerepairs', element: <DeviceRepairList /> },
            { path: 'devicereparirupdate/edit/:id', element: <DeviceRepairUpdate /> },
          ],
        },
      ],
    },
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
