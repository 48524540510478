function request(url, data = false, method = 'GET') {
  return new Promise((resolve, reject) => {
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json', // JSON verisi gönderiyoruz
    };

    if (data && method === 'POST') {
      headers['Content-Type'] = 'application/json'; // JSON verisi gönderiyoruz
    }
    const options = {
      method,
      headers,
    };

    if (data && method === 'POST') {
      options.body = JSON.stringify(data);
    }

    fetch(process.env.REACT_APP_API_URL + url, options)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        if (response.status === 400) {
          // Durum kodu 400 ise, hata mesajını almak için response metnini döndürün
          return response.text().then((errorText) => {
            throw new Error(errorText);
          });
        }
        throw new Error('Response not OK');
      })
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export const post = (url, data) => request(url, data, 'POST');
export const get = (url) => request(url);
