import PropTypes from 'prop-types';
// @mui
import { Box, Card, Typography, Stack } from '@mui/material';
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { toast, ToastContainer } from 'react-toastify';
import { deleteOldDevice } from '../../../services';

// utils
import Label from '../../../components/label';


const StyledProductImg = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
});

// ----------------------------------------------------------------------

ShopProductCard.propTypes = {
  product: PropTypes.object,
  onDelete: PropTypes.func,
};

export default function ShopProductCard({ product,onDelete }) {
  const { telephoneModelName, salePrice, color, imagePath, oldDeviceId,deviceCategory } = product;
  
  const deleteDevice = (id) => {
    const confirmDelete = window.confirm('Ürünü Silmek İstediğinizi Emin Misiniz ? Bu İşlem Geri Alınamaz');
    if (confirmDelete) {
      deleteOldDevice(id)
        .then((res) => {
          if (res.success === true) {
            toast.success(res.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
            setTimeout(() => {
              onDelete();
            }, 1500);
          }
        })
        .catch((err) => {
          toast.error(err.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        });
    }
  };
  return (
    <Card>
      <Box sx={{ pt: '100%', position: 'relative' }}>
        <ToastContainer />
        {deviceCategory && (
          <Label
            variant="filled"
            color={(deviceCategory === '2.El Cihaz' && 'error') || 'secondary'}
            sx={{
              zIndex: 9,
              top: 16,
              right: 16,
              position: 'absolute',
              color:'white'
            }}
          >
            {deviceCategory}
          </Label>
        )}
        <StyledProductImg alt={telephoneModelName} src={`https://subdomainapi.emirtek.net${imagePath}`} />
      </Box>

      <Stack spacing={2} sx={{ p: 3 }}>

        <Label color="default">
          <Typography variant="subtitle2" noWrap>
            {telephoneModelName}
          </Typography>
        </Label>


        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="subtitle2" noWrap>
            Renk : {color}
          </Typography>
          <Typography variant="subtitle1">

            {(salePrice)} ₺
          </Typography>
        </Stack>

        <Link to={`/dashboard/olddeviceupdate/edit/${oldDeviceId}`}>
          <Label color="info">
            <Typography variant="subtitle2" noWrap>
              Güncelle / Satış Yap
            </Typography>
          </Label>
        </Link>
        <Link onClick={() => deleteDevice(oldDeviceId)}>
          <Label color="error">
            <Typography variant="subtitle2" noWrap>
              Sil
            </Typography>
          </Label>
        </Link>
      </Stack>
    </Card>
  );
}
