import {post,get} from './request'

export const getTelephoneMaterialPrices = () => get('/TelephoneMaterialPrices/getall')
export const getTelephoneMaterialPriceDetails = () => get('/TelephoneMaterialPrices/getallwithdetail')
export const getTelephoneMaterialPriceById = id => get(`/TelephoneMaterialPrices/getbyid?telephoneMaterialPriceId=${id}`)
export const getTelephoneMaterialPriceByMaterialId = id => get(`/TelephoneMaterialPrices/getallwithdetailbyid?telephoneMaterialId=${id}`)
export const deleteTelephoneMaterialPrice = id => post(`/TelephoneMaterialPrices/delete/${id}`)
export const addTelephoneMaterialPrice = data => post('/TelephoneMaterialPrices/add',data)
export const updateTelephoneMaterialPrice = data => post('/TelephoneMaterialPrices/update',data)

