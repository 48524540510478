import * as React from 'react';
import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    Card,
    TableRow,
    TableBody,
    TableCell,
    Stack,
    Typography,
    TableContainer,
    Table,
    Checkbox,
    Box,
    InputLabel,
    FilledInput,
    FormControl,
    TablePagination,
    Button,
    IconButton,
    Paper,
    Container
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useParams } from 'react-router-dom';
import { UserListHead, UserListToolbar } from '../../../sections/@dashboard/user';
import Scrollbar from '../../../components/scrollbar';
import Label from '../../../components/label';

import { addTelephoneMaterialStockMovement, deleteTelephoneMaterialStockMovement, getTelephoneMaterialStockMovementByStockId, getTelephoneMaterialStockById, getTelephoneMaterialById, updateTelephoneMaterialStockMovement } from '../../../services';


const TABLE_HEAD = [

    { id: 'telephoneMaterialStockQuantityIncreasing', label: 'Stok Giriş', alignRight: false },
    { id: 'totalStockDelete', label: 'Stok Çıkış', alignRight: false },
    { id: 'totalStockAdd', label: 'Toplam Stok', alignRight: false },
    { id: 'actionName', label: 'Stok Hareketi', alignRight: false },
    { id: 'note', label: 'Detay', alignRight: false },
    { id: 'uDate', label: 'Son Güncelleme', alignRight: false },

    { id: '', label: 'İşlemler', alignRight: false },
];

// ----------------------------------------------------------------------
function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(
            array,
            (telephonematerialstockdetail) => telephonematerialstockdetail.telephoneMaterialStockQuantityIncreasing.toLowerCase().indexOf(query.toLowerCase()) !== -1
        );
    }
    return stabilizedThis.map((el) => el[0]);
}

export default function TelephoneMaterialStockUpdate() {

    const [increasingStock, setIncreasingStock] = useState('0');
    const [decreasingStock, setDecreasingStock] = useState('0');
    const [totalStock, setTotalStock] = useState('0');


    const [page, setPage] = useState(0);
    const [order, setOrder] = useState('asc');
    const [selected, setSelected] = useState([]);
    const [orderBy, setOrderBy] = useState('name');
    const [filterName, setFilterName] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(5);


    const [telephonematerialstockdetails, setTelephoneMaterialStockDetails] = useState([])
    const { id: telephoneMaterialStockId } = useParams();
    const [telephonematerialid, setTelephoneMaterialId] = useState([])
    const [telephonematerialname, setTelephoneMaterialName] = useState([])
    const [editingRow, setEditingRow] = useState(null);
    const [stockmovementid, setTelephoneMaterialStockMovementId] = useState(null);

    

    useEffect(() => {
        const toplamStock = increasingStock - decreasingStock
        setTotalStock(toplamStock);
        getById(telephoneMaterialStockId)
        getById2(telephoneMaterialStockId)
        getById3(telephonematerialid)
    }, [decreasingStock, increasingStock, telephoneMaterialStockId, telephonematerialid])


    const getById = (id) => {
        getTelephoneMaterialStockById(id)
            .then((data) => {
                // Set the fetched data to the state
                setTelephoneMaterialId(data.telephoneMaterialId);
            })
            .catch((err) => console.log(err));
    }

    const getById2 = (id) => {
        getTelephoneMaterialStockMovementByStockId(id)
            .then((data) => {
                // Set the fetched data to the state
                setTelephoneMaterialStockDetails(data);
            })
            .catch((err) => console.log(err));
    }

    const getById3 = (id) => {
        getTelephoneMaterialById(id)
            .then((data) => {
                // Set the fetched data to the state
                setTelephoneMaterialName(data.telephoneMaterialName);
            })
            .catch((err) => console.log(err));
    }

    const submitHandleForStockMovement = (e) => {
        e.preventDefault();
        const data = {
            telephoneMaterialId: telephonematerialid,
            telephoneMaterialStockQuantityIncreasing: increasingStock,
            telephoneMaterialStockQuantityDecreasing: decreasingStock,
            telephoneMaterialStockQuantity: totalStock,
            telephoneMaterialStockId
        };
        const data2 = {
            telephoneMaterialId: telephonematerialid,
            telephoneMaterialStockQuantityIncreasing: increasingStock,
            telephoneMaterialStockQuantityDecreasing: decreasingStock,
            telephoneMaterialStockQuantity: totalStock,
            telephoneMaterialStockId,
            telephoneMaterialStockMovementId: stockmovementid
        };
        if (editingRow) {
            updateTelephoneMaterialStockMovement(data2)
                .then((res) => {
                    if (res.success === true) {
                        toast.success(res.message, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        });

                    }
                    getById2(telephoneMaterialStockId)
                    setIncreasingStock('0')
                    setDecreasingStock('0')
                    setTotalStock('0')
                })
                .catch((err) => {
                    toast.error(err.message, {
                        position: toast.POSITION.BOTTOM_RIGHT
                    })
                });
        } else {
            addTelephoneMaterialStockMovement(data)
                .then((res) => {
                    if (res.success === true) {
                        toast.success(res.message, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        });

                    }
                    getById2(telephoneMaterialStockId)
                    setIncreasingStock('0')
                    setDecreasingStock('0')
                    setTotalStock('0')
                })
                .catch((err) => {
                    toast.error(err.message, {
                        position: toast.POSITION.BOTTOM_RIGHT
                    })
                });
        }

    }

    const deleteMaterialStock = (id) => {
        deleteTelephoneMaterialStockMovement(id)
            .then((res) => {
                if (res.success === true) {
                    toast.success(res.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                }
                getById2(telephoneMaterialStockId)
            })
            .catch((err) => {
                toast.error(err.message, {
                    position: toast.POSITION.BOTTOM_RIGHT
                })
            });
    };

    function formatDate(dateString) {
        // Tarih dizesini JavaScript Date nesnesine dönüştür
        const date = new Date(dateString);

        // Tarih nesnesini istediğiniz formata biçimlendirin (örneğin: yyyy-MM-dd)
        const formattedDate = date.toLocaleDateString('tr-TR', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
        });

        return formattedDate;
    }
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = telephonematerialstockdetails.map((n) => n.telephoneMaterialStockQuantityIncreasing);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const handleFilterByName = (event) => {
        setPage(0);
        const query = event.target.value.toLowerCase(); // Arama sorgusunu küçük harfe çevirin
        setFilterName(query);
    };

    const startEditing = (row, telephoneMaterialStockMovementId) => {
        setEditingRow(row);
        setIncreasingStock(row.telephoneMaterialStockQuantityIncreasing);
        setDecreasingStock(row.telephoneMaterialStockQuantityDecreasing);
        setTotalStock(row.telephoneMaterialStockQuantity);
        setTelephoneMaterialStockMovementId(telephoneMaterialStockMovementId); // Set the ID for editing
    };

    const filteredUsers = applySortFilter(telephonematerialstockdetails, getComparator(order, orderBy), filterName);
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - telephonematerialstockdetails.length) : 0;
    const isNotFound = !filteredUsers.length && !!filterName;

    return (

        <>
            <Helmet>
                <title> Yeni Malzeme Stoğu </title>
            </Helmet>
            <Container>
                <Box sx={{ width: '100%', typography: 'body1' }}>
                    <Card>
                        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                            <Typography variant="h4" gutterBottom sx={{ display: 'flex', flexWrap: 'wrap', margin: '20px' }}>
                                Lütfen Stok Bilgilerini Giriniz
                            </Typography>
                            <Typography variant="h4" gutterBottom sx={{ display: 'flex', flexWrap: 'wrap', margin: '20px' }}>
                                Ürün : {telephonematerialname}
                            </Typography>
                        </Stack>
                        <form onSubmit={submitHandleForStockMovement}>
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', margin: '20px' }}>
                                <div style={{ display: 'flex', gap: '16px' }}>
                                    <FormControl sx={{ m: 1, width: '30ch' }} variant="filled">
                                        <InputLabel htmlFor="filled-adornment-amount">Eklenecek Stok (Adet)</InputLabel>
                                        <FilledInput
                                            id="filled-adornment-amount"

                                            value={increasingStock}
                                            onChange={(e) => setIncreasingStock(e.target.value)}
                                        />
                                    </FormControl>
                                    <FormControl sx={{ m: 1, width: '30ch' }} variant="filled">
                                        <InputLabel htmlFor="filled-adornment-amount">Çıkarılacak Stok (Adet)</InputLabel>
                                        <FilledInput
                                            id="filled-adornment-amount"
                                            value={decreasingStock}
                                            onChange={(e) => setDecreasingStock(e.target.value)}
                                        />
                                    </FormControl>
                                    <FormControl sx={{ m: 1, width: '30ch' }} variant="filled">
                                        <InputLabel htmlFor="filled-adornment-amount">Eklenecek Toplam Stok</InputLabel>
                                        <FilledInput
                                            id="filled-adornment-amount"
                                            readOnly
                                            value={totalStock}
                                            onChange={(e) => setTotalStock(e.target.value)}
                                        />
                                    </FormControl>
                                </div>

                            </Box>
                            <Button
                                sx={{ m: 3 }}
                                color="primary"
                                variant="contained"
                                type='submit'>
                                {editingRow ? 'Güncelle' : 'Ekle'}
                            </Button>
                        </form>
                    </Card>
                    <br />
                    <Card>

                        <Card>
                            <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />
                            <Scrollbar>
                                <TableContainer sx={{ minWidth: 800 }}>
                                    <Table>
                                        <UserListHead
                                            order={order}
                                            orderBy={orderBy}
                                            headLabel={TABLE_HEAD}
                                            rowCount={telephonematerialstockdetails.length}
                                            numSelected={selected.length}
                                            onRequestSort={handleRequestSort}
                                            onSelectAllClick={handleSelectAllClick}
                                        />

                                        <TableBody>
                                            {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                                const { telephoneMaterialStockMovementId, telephoneMaterialStockQuantityIncreasing, telephoneMaterialStockQuantityDecreasing, telephoneMaterialStockQuantity, updatedDate, actionName,note } = row;
                                                const selectedUser = selected.indexOf(telephoneMaterialStockMovementId) !== -1;

                                                return (
                                                    <TableRow hover key={telephoneMaterialStockMovementId} tabIndex={-1} role="checkbox" selected={selectedUser}>
                                                        <TableCell padding="checkbox">
                                                            <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, telephoneMaterialStockMovementId)} />
                                                        </TableCell>
                                                        <TableCell align="left" ><Label color="primary">{telephoneMaterialStockQuantityIncreasing}</Label></TableCell>
                                                        <TableCell align="left">
                                                            <Label color="error"> {telephoneMaterialStockQuantityDecreasing}</Label>
                                                        </TableCell>
                                                        <TableCell align="left" > <Label color="success">{telephoneMaterialStockQuantity}</Label></TableCell>
                                                        <TableCell align="left" > <Label color="info">{actionName}</Label></TableCell>
                                                        <TableCell align="left" > <Label color="info">{note}</Label></TableCell>
                                                        <TableCell align="left">{formatDate(updatedDate)}</TableCell>
                                                        <TableCell>
                                                            <IconButton aria-label="delete" color="error" onClick={() => deleteMaterialStock(telephoneMaterialStockMovementId)} >
                                                                <DeleteIcon />
                                                            </IconButton>
                                                            <IconButton
                                                                aria-label="edit"
                                                                color="primary"
                                                                onClick={() => startEditing(row, telephoneMaterialStockMovementId)}
                                                            >
                                                                <EditIcon />
                                                            </IconButton>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                            {emptyRows > 0 && (
                                                <TableRow style={{ height: 53 * emptyRows }}>
                                                    <TableCell colSpan={6} />
                                                </TableRow>
                                            )}
                                        </TableBody>
                                        {isNotFound && (
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                                        <Paper
                                                            sx={{
                                                                textAlign: 'center',
                                                            }}
                                                        >
                                                            <Typography variant="h6" paragraph>
                                                                Sonuç Bulunamadı
                                                            </Typography>

                                                            <Typography variant="body2">
                                                                aramak istediğiniz &nbsp;
                                                                <strong>&quot;{filterName}&quot;</strong>.
                                                                <br /> Aradığınız kelimelere uygun sonuç bulunmamaktadır.
                                                            </Typography>
                                                        </Paper>
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        )}
                                    </Table>
                                </TableContainer>
                            </Scrollbar>

                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={telephonematerialstockdetails.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Card>
                    </Card>
                    <ToastContainer />
                </Box>
            </Container>
        </>
    );
}