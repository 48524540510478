import * as React from 'react';
import { useState, useEffect, useCallback } from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { MuiFileInput } from 'mui-file-input'
import { toast, ToastContainer } from 'react-toastify';
import tr from "dayjs/locale/tr";
import 'react-toastify/dist/ReactToastify.css';
import {
    Card,
    Container,
    Stack,
    Typography,
    Autocomplete,
    Box,
    Input,
    FilledInput,
    InputLabel,
    InputAdornment,
    FormControl,
    TextField,
    Button,
    ListItem,
    ListItemText,
    List,

} from '@mui/material';



import { addOldDevice, getTelephoneModels } from '../../../services';



export default function OldDeviceAdd() {
    const [buyingprice, setBuyingPrice] = useState('0');
    const [sellingprice, setSellingPrice] = useState('0');
    const [totalPrice, setTotalPrice] = useState('0');
    const [selectedModel, setSelectedModel] = useState(null);
    const [models, setModels] = useState([])

    const navigate = useNavigate();

    const devicememories = ['16 GB', '32 GB', '64 GB', '128 GB', '256 GB','512 GB','1 TB'];
    const [deviceMemory, setDeviceMemory] = useState('');
    const [deviceMemoryValue, setDeviceMemoryValue] = useState('');

    const deviceCategories = ['1.El Cihaz', '2.El Cihaz',];
    const [deviceCategory, setDeviceCategory] = useState('');
    const [deviceCategoryInputValue, setDeviceCategoryValue] = useState('');

    const devicecolors = ['Siyah', 'Beyaz', 'Gold', 'Mavi', 'Yeşil', 'Mor'];
    const [color, setcolor] = useState('');
    const [colorInputValue, setColorInputValue] = useState('');

    const [file, setFile] = useState(null)
    const handleChange = (newFile) => {
        setFile(newFile)
    }

    const [imeiNo, setImeiNo] = useState('')
    const [guranteeTerm, setGuranteeTerm] = useState()
    const [buyingCustomerName, setBuyingCustomerName] = useState('')
    const [buyingCustomerTelephoneNo, setBuyingCustomerTelephoneNo] = useState('');
    const [buyingDate, setBuyingDate] = useState()

    useEffect(() => {
        const addingPrice = sellingprice - buyingprice
        setTotalPrice(addingPrice.toFixed(2));// Toplam Fiyatı iki ondalık basamağa yuvarla

    }, [buyingprice, sellingprice])

    useEffect(() => {
        getAllTelephoneModels()
    })

    const getAllTelephoneModels = () => {
        getTelephoneModels()
            .then((data) => setModels(data))
            .catch((err) => console.log(err));
    };

    const isOptionEqualToValue = useCallback((option, value) => option.value === value.value, []);

    const handleModelChange = (newValue) => {
        setSelectedModel(newValue);
    };

    const handleGuranteeTermChange = (date) => {
        setGuranteeTerm(date);
    };
    const handleBuyingDateChange = (date) => {
        setBuyingDate(date);
    };
    
    const submitHandle = (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append("telephoneModelId", selectedModel.telephoneModelId);
        formData.append("deviceMemory", deviceMemory);
        formData.append("color", color);
        
        if(guranteeTerm != null){
            formData.append("guaranteeTerm", guranteeTerm);
        }
         
        formData.append("buyingCustomerName", buyingCustomerName);
        formData.append("buyingCustomerTelephoneNo", buyingCustomerTelephoneNo);
        formData.append("imeiNo", imeiNo);
        formData.append("buyingDate", buyingDate); 
        formData.append("deviceCategory",deviceCategory)
        formData.append("buyingPrice", buyingprice);
        formData.append("salePrice", sellingprice);
        formData.append("image", file); 


        addOldDevice(formData)
            .then((res) => {
                if (res.success === true) {
                    toast.success(res.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });

                    // 1,5 saniye sonra yönlendirme yap
                    setTimeout(() => {
                        navigate('/dashboard/olddevices');
                    }, 1500); // 1,5 saniye (1500 milisaniye) bekleyecek
                }
            })
            .catch((err) => console.log(err));
    }


    return (

        <>
            <Helmet>
                <title>  Cihaz Ekleme </title>
            </Helmet>
            <Container>
                <form onSubmit={submitHandle} encType='multipart/form-data' >
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                        <Typography variant="h4" gutterBottom>
                           Cihaz Ekleme
                        </Typography>

                    </Stack>
                    <Card>
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', margin: '20px' }}>


                            <Autocomplete
                                id="country-select-demo"
                                sx={{ m: 1, width: '30ch' }}
                                options={models}
                                autoHighlight
                                getOptionLabel={(option) => option.telephoneModelName}
                                isOptionEqualToValue={isOptionEqualToValue}
                                renderOption={(props, option) => (
                                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                        {option.telephoneModelName}
                                    </Box>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Model Seçiniz"
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: 'new-password', // disable autocomplete and autofill
                                        }}
                                    />
                                )}
                                value={selectedModel}
                                onChange={(event, newValue) => handleModelChange(newValue)}
                            />
                            <Autocomplete
                                value={deviceMemory}
                                isOptionEqualToValue={isOptionEqualToValue}
                                onChange={(event, newValue) => {
                                    setDeviceMemory(newValue);
                                }}
                                inputValue={deviceMemoryValue}
                                onInputChange={(event, newInputValue) => {
                                    setDeviceMemoryValue(newInputValue);
                                }}
                                id="controllable-states-demo"
                                options={devicememories}
                                sx={{ m: 1, width: '30ch' }}
                                renderInput={(params) => <TextField {...params} label="GB" />}
                            />
                            <Autocomplete
                                value={color}
                                isOptionEqualToValue={isOptionEqualToValue}
                                onChange={(event, newValue) => {
                                    setcolor(newValue);
                                }}
                                inputValue={colorInputValue}
                                onInputChange={(event, newInputValue) => {
                                    setColorInputValue(newInputValue);
                                }}
                                id="controllable-states-demo"
                                options={devicecolors}
                                sx={{ m: 1, width: '30ch' }}
                                renderInput={(params) => <TextField {...params} label="Renk" />}
                            />

                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={tr}>
                                <DemoContainer components={['DatePicker']}>
                                    <DatePicker label="Garanti Tarihi" sx={{ m: 1, width: '30ch' }} format="DD/MM/YYYY" value={guranteeTerm} onChange={handleGuranteeTermChange} />
                                </DemoContainer>
                            </LocalizationProvider>

                            <FormControl sx={{ m: 1, marginTop: '15px', width: '30ch' }}>
                                <TextField id="outlined-basic" label="Alınan Kişi" variant="outlined" value={buyingCustomerName} onChange={(e) => setBuyingCustomerName(e.target.value)} />
                            </FormControl>
                            <FormControl sx={{ m: 1, marginTop: '15px', width: '30ch' }} >
                                <TextField id="outlined-basic" label="Alınan Kişi Numarası" variant="outlined" value={buyingCustomerTelephoneNo} onChange={(e) => setBuyingCustomerTelephoneNo(e.target.value)} inputProps={{
                                    maxLength: 11
                                }} />
                            </FormControl>
                            <FormControl sx={{ m: 1, width: '30ch' }} >
                                <TextField id="outlined-basic" label="IMEI No" variant="outlined" value={imeiNo} onChange={(e) => setImeiNo(e.target.value)} />
                            </FormControl>
                            <MuiFileInput sx={{ m: 1, width: '30ch' }} value={file} onChange={handleChange} label="Resim Seçiniz" />
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={tr}>
                                <DemoContainer components={['DatePicker']}>
                                    <DatePicker label="Alış Tarihi" sx={{ marginLeft: '10px', width: '30ch' }} format="DD/MM/YYYY" value={buyingDate} onChange={handleBuyingDateChange} />
                                </DemoContainer>
                            </LocalizationProvider>
                            <Autocomplete
                                value={deviceCategory}
                                isOptionEqualToValue={isOptionEqualToValue}
                                onChange={(event, newValue) => {
                                    setDeviceCategory(newValue);
                                }}
                                inputValue={deviceCategoryInputValue}
                                onInputChange={(event, newInputValue) => {
                                    setDeviceCategoryValue(newInputValue);
                                }}
                                id="controllable-states-demo"
                                options={deviceCategories}
                                sx={{ m: 1, width: '30ch' }}
                                renderInput={(params) => <TextField {...params} label="Cihaz Türü" />}
                            />
                        </Box>
                    </Card>
                    <br />
                    <Card>
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', margin: '20px' }}>

                            <div>
                                <FormControl sx={{ m: 1, width: '30ch' }} variant="filled">
                                    <InputLabel htmlFor="filled-adornment-amount">Alış Fiyatı</InputLabel>
                                    <FilledInput
                                        id="filled-adornment-amount"
                                        startAdornment={<InputAdornment position="start">₺</InputAdornment>}
                                        value={buyingprice}
                                        onChange={(e) => setBuyingPrice(e.target.value)}
                                    />
                                </FormControl>

                                <FormControl sx={{ m: 1, width: '30ch' }} variant="filled">
                                    <InputLabel htmlFor="filled-adornment-amount">Satış Fiyatı</InputLabel>
                                    <FilledInput
                                        id="filled-adornment-amount"
                                        startAdornment={<InputAdornment position="start">₺</InputAdornment>}
                                        value={sellingprice}
                                        onChange={(e) => setSellingPrice(e.target.value)}
                                    />
                                </FormControl>

                            </div>
                            <div>
                                <FormControl sx={{ m: 1, width: '50ch' }} variant="standard">
                                    <InputLabel htmlFor="standard-adornment-amount">Kâr</InputLabel>
                                    <Input
                                        readOnly
                                        id="standard-adornment-amount"
                                        startAdornment={<InputAdornment position="start">₺</InputAdornment>}
                                        value={totalPrice === '' ? '0' : totalPrice}
                                        onChange={(e) => setTotalPrice(e.target.value)}
                                    />
                                </FormControl>
                            </div>
                        </Box>
                    </Card>                <ToastContainer />
                    <Button
                        sx={{ m: 3 }}
                        color="primary"
                        variant="contained"
                        type='submit'
                    >
                        Kaydet
                    </Button>
                    <hr />
                    <List sx={{ m: 1 }}>
                        <ListItem>
                            <ListItemText primary="Alış Fiyatı" secondary={`${buyingprice} ₺`} />
                            <ListItemText primary="Satış Fiyatı" secondary={`${sellingprice} ₺`} />
                            <ListItemText primary="Kâr" secondary={`${totalPrice} ₺`} />
                        </ListItem>
                    </List>
                </form>
            </Container >
        </>
    );
}